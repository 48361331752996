import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import { useCallback, useMemo } from "react";

import { Form, Formik } from "formik";

import { TextField } from "../../material/TextField";

import { AssetUploadSchema, AssetUploadType } from "../../yup-schemas/assetSchema";

import useDataClient from "../../axios/dataClient";

import { error } from "../ErrorDisplay";

import { BlobFile } from "./folder";

import { UploadFile } from ".";

interface UploadAssetDialogProps {
    open: boolean;
    filePath: string;
    isPublic: boolean;
    isSingleFile: boolean;
    onCancel: () => void;
    onConfirm: (files: BlobFile[]) => void;
    existingData?: AssetUploadType;
    existingAssets: BlobFile[];
}

const validFileTypes = ["png", "jpg", "jpeg", "mp3", "mp4", "pdf", "svg", "gif", "ico", "mpeg", "csv", "json"];

const defaultValue: AssetUploadType = {
    fileNames: [],
    extensions: [],
    files: []
};

export const UploadAssetDialog = ({ open, filePath, isPublic, isSingleFile, onCancel, onConfirm, existingData, existingAssets }: UploadAssetDialogProps) => {

    const { post } = useDataClient();

    const onConfirmCallback = useCallback(async (formikValues: AssetUploadType) => {
        const form = new FormData();
        if (new Set(formikValues.fileNames).size !== formikValues.fileNames.length) {
            error("File names must be unique");
            return;
        };

        for (let i = 0; i <formikValues.files.length; i++) {
            form.append("files", formikValues.files[i]);
            form.append("fileNames", formikValues.fileNames[i]);
            form.append("extensions", formikValues.extensions[i]);
        }
        form.append("filePath", filePath);
        form.append("isPublic", isPublic.toString());
        const newFiles = await post<{ successfulFiles: BlobFile[]; errors: any[]}>("api/asset/upload", form);
        for (const fileError of newFiles.errors) {
            error(fileError);
        }

        onConfirm(newFiles.successfulFiles);
    },[onConfirm, post, filePath, isPublic, ]);

    const validationSchema = useMemo(() => {
        return AssetUploadSchema(existingAssets);
    }, [existingAssets]);

    return (
        <Dialog open={open}>
            <DialogTitle className="bbot-dialog-title">
                <Typography>Upload Files</Typography>
            </DialogTitle>
            <Formik<AssetUploadType>
                initialValues={existingData ?? defaultValue}
                validationSchema={validationSchema}
                onSubmit={onConfirmCallback}>
                {(props) =>
                    (<Form>
                        <DialogContent className="bbot-dialog-content bb-global-modal">
                            {props.values.files.map((file, index) => (
                                <TextField key={index} label="Name" name={`fileNames.${index}`} value={file.name} required />
                            ))}
                            <UploadFile validFileTypes={validFileTypes} isSingleFile={isSingleFile} />
                        </DialogContent>
                        { /* We need padding bottom here otherwise the spinner overlaps the edge and makes a scroll appear. See #88436 */ }
                        <DialogActions className="bbot-dialog-actions" sx={{ paddingBottom: "9px" }}>
                            <Button onClick={onCancel}>Cancel</Button>
                            {props.isSubmitting ? <CircularProgress /> : <Button className="bpb" type="submit" disabled={!props.isValid}>Upload</Button>}
                        </DialogActions>
                    </Form>)
                }
            </Formik>
        </Dialog>
    );
};

import { Box, Button, Alert, Container } from "@mui/material";

import { useCallback, useState, useEffect } from "react";

import { XeroDialog } from "./xero-dialog";

export const Connections = () => {

    useEffect(() => {
        document.title = 'Beebot AI - Integrations';
    },[]);

    const [xeroDialogOpen, setXeroDialogOpen] = useState(false);
    const addXeroConnection = useCallback(() => {
        setXeroDialogOpen(true);
    }, []);

    const onXeroCancel = useCallback(() => {
        setXeroDialogOpen(false);
    }, []);

    const onXeroSuccess = useCallback(() => {
        setXeroDialogOpen(false);
    }, []);

    return (
        <Container>
            <Box>
                <Box className="bb-title-bar"><h2>Integrations</h2></Box>
                <Alert className="bb-title-info" severity="info">Manage your integrations to third party applications.</Alert>
                <Button onClick={addXeroConnection}>
                    ADD XERO
                </Button>
                <XeroDialog isOpen={xeroDialogOpen} onCancel={onXeroCancel} onSaveSuccess={onXeroSuccess}></XeroDialog>
            </Box>
        </Container>
    );
};

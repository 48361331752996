import { Card, Skeleton, Typography, useTheme } from "@mui/material";

interface Props {
    loading?: boolean;
    title: string;
    stat: string;
    stat_pc?: string;
}

export const StatCard = ({ loading, title, stat, stat_pc  }: Props) => {
    const theme = useTheme();

    const textColor = theme.palette.primary.contrastText;

    return (<Card sx={{
        flex: 1,
        minWidth: "9rem",
        padding: ".7rem",
        backgroundColor: theme.palette.text.primary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
    }}
    className="stat-card">
        <Typography fontSize="max(15px)" color={textColor} className="stat-card-title">{title}</Typography>
        <Typography fontSize="max(35px)" color={textColor} className="stat-card-value">
            {
                loading
                    ? <Skeleton sx={{ backgroundColor: "silver" }} />
                    : stat
            }
        </Typography>
        { stat_pc &&
            <Typography fontSize="max(15px, 2cqw)" color={textColor} className="stat-card-title">{stat_pc}</Typography>
        }
    </Card>);
};

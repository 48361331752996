import {
    Button,
    Box,
    Grid,
    styled
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { Form } from '@rjsf/mui';

import React from "react";

import { JSONSchema7 }  from "json-schema";

import validatorAjv8 from "@rjsf/validator-ajv8";

import { UiSchema } from "@rjsf/utils";

import { ProfileWidget } from "../customWidget/ProfileWidget/ProfileWidget";

import { OptionWidget } from "../customWidget/ProfileWidget/OptionWidget";

import { SmartPage } from "../../../schemas/smartPages/schema";

import smartPagesProfileModel from "../../../schemas/smartPages/smartPagesProfileSchema.json";

import { removeExtension } from "../../../services/api/FileNameService";

import { AccordionFieldTemplate } from "../../applications/customWidget/FieldTemplate/AccordionFieldTemplate";

export interface AppSmartPageProfileFormProps {
    currentSmartPage: SmartPage;
    onUpdate: (e: any) => void;
    onError: (e: any) => void;
    onCancel: (e: any) => void;
}

export const AppSmartPageProfileForm: React.FC<AppSmartPageProfileFormProps> = ({ currentSmartPage, onUpdate, onError, onCancel }) => {
    const [currentSmartPageName, setCurrentSmartPageName] = React.useState<string>();

    const customWidgets = React.useMemo(() => ({
        ProfileWidget,
        OptionWidget
    }), []);

    React.useEffect(() => {
        if (currentSmartPage?.name) {
            setCurrentSmartPageName(removeExtension(currentSmartPage?.name));
        }
    }, [currentSmartPage?.name]);

    const onChange = React.useCallback((e: any): void => {
        const newSmartPageProfile = e.formData as SmartPage;
        if (newSmartPageProfile?.target?.profile && currentSmartPageName) {
            localStorage.setItem(
                currentSmartPageName,
                JSON.stringify(newSmartPageProfile.target.profile));

            window.dispatchEvent(new StorageEvent("profileStorageChange"));
        }
    }, [currentSmartPageName]);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "target": {
            "ui:title": "",
            "profile": {
                "ui:options": {
                    "orderable": false
                },
                "items": {
                    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
                    "name": {
                        "ui:widget": "ProfileWidget"
                    },
                    "options": {
                        "ui:options": {
                            "orderable": false
                        },
                        "items": {
                            "ui:ObjectFieldTemplate": AccordionFieldTemplate,
                            "ui:widget": "OptionWidget",
                        }
                    },
                }
            }
        },
        "ui:title": ""
    };

    const ModalBox = styled(Box)(() => ({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        padding: 15,
        p: 4,
        overflow: "auto",
        maxHeight: "60%",
    }));

    return (
        <ModalBox className="bb-edit-page-modal">
            <>
                <Box className="bb-flex bb-pe-modal-header">
                    <h2>Smart Page: {currentSmartPage.name}</h2>
                    <Button className="bb-ml-auto" onClick={onCancel} variant="text">
                        <CloseIcon></CloseIcon>
                    </Button>
                </Box>
                <Form
                    schema={smartPagesProfileModel as JSONSchema7}
                    formData={currentSmartPage}
                    validator={validatorAjv8}
                    onError={onError}
                    onSubmit={onUpdate}
                    onChange={onChange}
                    widgets={customWidgets}
                    className={"bb-pe-modal-form"}
                    uiSchema={uiSchema}
                    formContext={{
                        smartPageName: currentSmartPageName
                    }}
                >
                    <Box className="bb-pe-modal-footer">
                        <Grid container spacing={1} className="bb-flex bb-fixed">
                            <Grid item>
                                <Button onClick={onCancel} variant="text">
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item className="bb-ml-auto">
                                <Button variant="contained" type="submit">
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Form>
            </>
        </ModalBox>
    );
};

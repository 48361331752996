import { useParams } from "react-router-dom";
import {
    Container,
    Box,
    Alert,
    CircularProgress
} from "@mui/material";

import { useState, useEffect } from "react";
import { UiSchema } from "@rjsf/utils";
import useDataClient from "../../../axios/dataClient";
import { getApp } from "../../../services/api/FrontendService";
import { AppViewModel } from "../../../services/model/apps/AppViewModel";
import { AppThemeForm } from "./AppThemeForm";
import { AppLinks } from "../AppLinksComponent";
import { Breadcrumb } from "../BreadCrumbComponent";

import { FavoriteProvider } from '../../favorites/FavoriteContext';
import { FavoriteButton } from '../../favorites/FavoriteButton';

export const uiSchema: UiSchema = {
    "ui:title": "",
};
export const AppTheme = () => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    }, [appId, get]);

    useEffect(() => {
        document.title = 'Beebot AI - Theme';
    },[]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Theme: {appData.displayName}</h2>
                    <FavoriteButton page={`applications/${appId}/theme`} displayName={`App: ${appData.displayName}`} displayModule={`Module: Theme`} />
                    <AppLinks />
                </Box>
                <Breadcrumb />
                <Alert className="bb-title-info bb-mb-2" severity="info">
                Manage the theme for your <b>{appData.displayName}</b> application.&nbsp;
                    <a rel="noreferrer" className="bb-mr-1" href={`https://product.bbotapp.com/?page=theme`} target="_blank">Learn More</a>
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column">
                    <AppThemeForm appId={appId}></AppThemeForm>
                </Box>
            </Container>
        </FavoriteProvider>
    );
};

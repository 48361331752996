import { AccordionFieldTemplate } from "../customWidget/FieldTemplate/AccordionFieldTemplate";

export const panelTypeUiSchema = {
    "items": {
        "panelType": {
            "ui:widget": "PanelTypeDropDownWidget"
        }
    }
};

export const columnWidthUiSchema = {
    "colSpan": {
        "ui:widget": "ColumnWidthDropDownWidget"
    }
};

export const linksUiSchema = {
    "items": {
        "ui:ObjectFieldTemplate": AccordionFieldTemplate,
        "linkAction": {
            "ui:widget": "LinkTypeWidget"
        },
        "linkThumbUrl": {
            "ui:widget": "MediaLibraryWidget"
        },
        "bannerImgUrl": {
            "ui:widget": "MediaLibraryWidget"
        },
        "imgUrl": {
            "ui:widget": "MediaLibraryWidget"
        },
        "ButtonBgColor": {
            "ui:widget": "ColourPicker"
        },
        "ButtonTextColor": {
            "ui:widget": "ColourPicker"
        }
    }
};

export const tabsUiSchema = {
    "items": {
        "ui:ObjectFieldTemplate": AccordionFieldTemplate,
        "body": {
            "ui:widget": "HtmlCustomWidget"
        }
    }
};

export const dbUiSchema = {
    "tableName": {
        "ui:widget": "DbTableWidget"
    },
    "template": {
        "ui:widget": "PageOptionsWidget"
    },
    "mapping": {
        "ui:ObjectFieldTemplate": AccordionFieldTemplate,
        "linkThumbUrl": {
            "ui:widget": "DbColumnWidget"
        },
        "linkText": {
            "ui:widget": "DbColumnWidget"
        },
        "linkUrl": {
            "ui:widget": "DbColumnWidget"
        },
        "linkType": {
            "ui:widget": "DbColumnWidget"
        },
        "linkAction": {
            "ui:widget": "DbColumnWidget"
        },
        "heading": {
            "ui:widget": "DbColumnWidget"
        },
        "body": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerLink": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerButtonTextColor": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerButtonBgColor": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerLinkText": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerButtonVisible": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerAltText": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerImgUrl": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerImgVisible": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerBgColor": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerPara": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerSubHeading": {
            "ui:widget": "DbColumnWidget"
        },
        "bannerHeading": {
            "ui:widget": "DbColumnWidget"
        }
    },
    "defaults": {
        "ui:ObjectFieldTemplate": AccordionFieldTemplate,
    },
    "filter": {
        "ui:ObjectFieldTemplate": AccordionFieldTemplate,
        "indexes": {
            "items": {
                "index_name": {
                    "ui:widget": "DbColumnWidget"
                }
            }
        }
    }
};

export const bannerDataUiSchema = {
    "items": {
        "ui:ObjectFieldTemplate": AccordionFieldTemplate,
        "bannerImgUrl": {
            "ui:widget": "MediaLibraryWidget"
        },
        "bannerBgColor": {
            "ui:widget": "ColourPicker"
        },
        "bannerButtonBgColor": {
            "ui:widget": "ColourPicker"
        },
        "bannerButtonTextColor": {
            "ui:widget": "ColourPicker"
        },
        "linkAction": {
            "ui:widget": "LinkTypeWidget"
        },
    }
};

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
    Container,
    Alert,
    Breadcrumbs
} from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import useDataClient from "../../axios/dataClient";
import { getBot } from "../../services/api/BotService";

import { BotDetailsModel } from "../../services/model/bot";

import { ConfigurationContext } from "../../ConfigurationContext";

import { BotEditInformationDialog } from "./BotEditInformationDialog";
import { BotSecretsCard } from "./BotSecrets/BotSecretsCard";
export const BotDetails = () => {
    const context = useContext(ConfigurationContext);
    const params = useParams();
    const botId = params.botId as string;
    const dateTimeFormatter = new Intl.DateTimeFormat("en-GB", {
        dateStyle: "short",
        timeStyle: "short",
    });
    let status: string = "";
    let statusStyle: { color: string } = { color: "" };
    let dateCreated: string = "";
    let lastUpdatedDate: string = "";

    const { get } = useDataClient();
    const { data, isLoading, refetch } = useQuery(["botDetails", botId], () =>
        getBot(get, botId)
    );

    const [editBot, setEditBot] = useState(false);
    const showEditBotDialog = useCallback(() => setEditBot(true), [setEditBot]);
    const hideEditBotDialog = useCallback(() => setEditBot(false), [setEditBot]);

    const navigateComposer = useCallback(() => {
        window.open(`${context.composer}/bot/${botId}`);
    }, [context, botId]);

    const botDetails = data as BotDetailsModel;
    const template = botDetails?.beeBotFile?.templateDetails;

    if (!isLoading) {
        status = botDetails.isActive ? "Active" : "Inactive";
        statusStyle = botDetails.isActive
            ? { color: "green" }
            : { color: "red" };

        dateCreated = dateTimeFormatter.format(new Date(botDetails.liveDate));
        lastUpdatedDate = dateTimeFormatter.format(
            new Date(botDetails.lastUpdatedDate)
        );
    }

    return (
        <>
            {isLoading ? (
                <>
                    <Box className="bb-tac"><CircularProgress /></Box>;
                </>
            ) : !data ? (
                <>
                    Error
                </>
            ) : (
                <>  <Container className="bb-bot-manage">
                    <Box className="bb-title-bar bb-mt-3">
                        <h2 className="bb-m0 bb-p0">Conversational Ai Bots: {botDetails.displayName}</h2>
                        <Box className="bb-ml-auto bb-align-items-center bb-flex">
                        </Box>
                    </Box>
                    <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                        <Link to="/bots">Bots</Link>
                        <Typography color="text.primary" className="bb-m0 bb-p bb-crumb-text">{botDetails.displayName}</Typography>
                    </Breadcrumbs>
                    <Alert className="bb-title-info bb-mb-2 bb-flex bb-align-center" severity="info">
                        Manage your conversational Ai chat bot.
                    </Alert>
                    <Box sx={{ width: "100%" }}>
                        <Grid
                            container
                            spacing={2}
                            marginTop="1rem"
                        >
                            <Grid item xs={4}>
                                <Card>
                                    <CardHeader title="Bot Information" sx={{ fontSize: " 1rem "  }} />
                                    <CardContent>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            component="div"
                                        >
                                            <List>
                                                <ListItem sx={{ padding: "0", fontWeight: "bold" }}>
                                                    <ListItemText
                                                        primary={`Display name: ${botDetails.displayName}`}
                                                    />
                                                </ListItem>
                                                <ListItem sx={{ padding: "0" }}>
                                                    <ListItemText
                                                        primary={`Logo: ${botDetails.logo}`}
                                                    />
                                                </ListItem>
                                                <ListItem sx={{ padding: "0" }}>
                                                    <ListItemText
                                                        primary={`Production Domains: ${botDetails.productionDomains.map(
                                                            (value) => value
                                                        )}`}
                                                    />
                                                </ListItem>
                                                <ListItem sx={{ padding: "0" }}>
                                                    <ListItemText
                                                        primary={`Live Date: ${dateCreated}`}
                                                    />
                                                </ListItem>
                                                <ListItem sx={{ padding: "0" }}>
                                                    <ListItemText
                                                        primary={`Date Created: ${dateCreated}`}
                                                    />
                                                </ListItem>
                                                <ListItem sx={{ padding: "0" }}>
                                                    <ListItemText
                                                        primary={`Last Updated: ${lastUpdatedDate}`}
                                                    />
                                                </ListItem>
                                                <ListItem sx={{ padding: "0" }}>
                                                    <ListItemText
                                                        sx={statusStyle}
                                                        primary={`Status: ${status}`}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Typography>
                                    </CardContent>
                                    <CardActions className="bb-card-footer"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}>
                                        <Button size="small" onClick={showEditBotDialog}>Edit</Button>
                                        <BotEditInformationDialog
                                            open={editBot}
                                            onBotUpdated={refetch}
                                            onClose={hideEditBotDialog}
                                            originalBotInformation={data} />
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card sx={{ height: "100%" }}>
                                    <CardHeader title="Dialogs" variant="h5" />
                                    <CardContent>
                                        {!template ? null :
                                            (<List>

                                                <Typography>
                                                    {template.mainDialog.name}
                                                </Typography>
                                                {template.subDialogs.map(
                                                    sub => (<Typography key={sub.name}>
                                                        {sub.name}
                                                    </Typography>
                                                    ))}
                                            </List>)
                                        }
                                    </CardContent>
                                    <CardActions className="bb-card-footer"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}>
                                        <Button size="small" className="bb-ml-auto" onClick={navigateComposer}>
                                            Open Editor
                                        </Button>
                                        {/* <Button
                                            size="small"
                                            component={Link}
                                            to={`/bots/${botId}/dialogs`}
                                            sx={{ marginLeft: "auto" }}
                                        >
                                            View
                                        </Button> */}
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <BotSecretsCard botId={botId} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                </>
            )}
        </>
    );
};

/* eslint-disable react/jsx-no-bind */

import { Box, Grid, Container, Typography, Alert, CircularProgress, FormLabel, FormGroup, FormControlLabel, Tooltip, Switch } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { Link } from "react-router-dom";

import { useCallback, useState, useEffect } from "react";

import { useQuery } from "react-query";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import useDataClient from "../../axios/dataClient";

import { getAnalyticsLiveAgentData } from "../../services/api/StatisticsService";

import { LiveAgentSummaryGrid } from "../Statistics/LiveAgentSummaryGrid";

import { getCustomerUsers } from "../../services/api/CustomerUserService";

import { CustomerUser } from "../../services/model/users/customerUser";

import {
    AnalyticsDataGrid,
    AnalyticsConfig_liveagentsessions,
    AnalyticsConfig_liveagentsummary,
    AnalyticsConfig_liveagentsessiondetails
} from "./AnalyticsDataGrid";

export function addUserData(data: any, users: any) {
    const ret = {} as any;
    for (const user of users) {
        try {
            const link = user.id ?? '';
            let new_val =  user.displayName ?? '';
            new_val = new_val === "" ? link : new_val;
            ret[link] = new_val;
        } catch {
            continue;
        }
    }
    const columnNamesToTransform = ['agentId'];
    const dataClone = structuredClone(data);
    // eslint-disable-next-line guard-for-in
    for (const dataset in dataClone) {
        for (const dataColumn of Object.keys(dataClone[dataset].data)) {
            if (columnNamesToTransform.includes(dataColumn) ) {
                for (const dataRow of Object.keys(dataClone[dataset].data[dataColumn]) as any) {
                    const rowVal = (dataClone[dataset].data[dataColumn][(dataRow as any)] as string );
                    if ( Object.keys(ret).includes(rowVal)) {
                        dataClone[dataset].data[dataColumn][(dataRow as string)] = ret[rowVal];
                    }
                }
            }
        }
    }
    return dataClone;

}

export const AnalyticsLiveAgent = () => {

    const [isProductionDomain, setProductionDomain] = useState(true);

    const [lastDays, setLastDays] = useState("604800");
    const [users, setUsers] = useState<CustomerUser[]>([]);
    const { get } = useDataClient();

    const handleChange = (event: SelectChangeEvent) => {
        setLastDays(event.target.value as string);
    };

    const handleChangePD = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProductionDomain(event.target.checked as boolean);
    };

    const getUsers = useCallback(async () => {
        const page = await getCustomerUsers(get);
        setUsers(current => [...current, ...page.items]);
    }, [get]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const analyticsData = useQuery([lastDays], () => getAnalyticsLiveAgentData(get, lastDays));

    if (!analyticsData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    if (analyticsData.isLoading) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    if (users.length === 0) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    if (!analyticsData.isLoading && users.length > 0) {
        analyticsData.data = addUserData(analyticsData.data, users);
    }

    return (
        <Container sx={{ marginBottom: "5rem" }} className="bb-analytics-page">
            <Box className="bb-title-bar bb-mt-4">
                <Typography variant="h2" className="bb-m0 bb-p0">Live Agent Analytics</Typography>
                <Box className="bb-ml-auto bb-align-items-center bb-flex">
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/applications">Applications</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Analytics</Typography>
            </Breadcrumbs>

            <Grid className="bb-mb-4" container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} md={12} lg={6}>
                    <Alert className="bb-title-info bb-mb-2" severity="info">
                        Check out your Live Agent statistics.
                    </Alert>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Box className="bb-analytics-dom">
                        <FormLabel component="legend">Included Domains</FormLabel>
                        <FormGroup>
                            <Tooltip title="Include Non-Production Domain Data?">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isProductionDomain}
                                            size="small"
                                            onChange={handleChangePD}
                                        />
                                    }
                                    label={isProductionDomain ? "Production Only" : "All"}
                                    labelPlacement='end'
                                />
                            </Tooltip>
                        </FormGroup>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="analytics-simple-select-label">Date Range</InputLabel>
                        <Select
                            labelId="analytics-simple-select-label"
                            id="analytics-simple-select"
                            value={lastDays}
                            label="Date Range"
                            onChange={handleChange}
                        >
                            <MenuItem value={"86400"}>Last 24 Hours</MenuItem>
                            <MenuItem value={"259200"}>Last 3 Days</MenuItem>
                            <MenuItem value={"604800"}>Last 7 Days</MenuItem>
                            <MenuItem value={"2592000"}>Last 30 Days</MenuItem>
                            <MenuItem value={"currentmonth"}>Current Month</MenuItem>
                            <MenuItem value={"lastmonth"}>Last Month</MenuItem>
                            <MenuItem value={"31536000"}>Last 12 Months</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box className="bb-mb-4 animated animatedFadeInUp fadeInUp4">
                <LiveAgentSummaryGrid loading={analyticsData.isLoading} data={analyticsData.data} />
            </Box>
            <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp7">
                <Grid item xs={12}>
                    {analyticsData.isLoading ? null : (
                        <>
                            <AnalyticsDataGrid
                                data={analyticsData.data?.agents}
                                config={AnalyticsConfig_liveagentsummary}
                                title="Agents"
                            />
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp8">
                <Grid item xs={12}>
                    {analyticsData.isLoading ? null : (
                        <>
                            <AnalyticsDataGrid
                                data={analyticsData.data?.conversation_summary}
                                config={AnalyticsConfig_liveagentsessions}
                                title="Conversations"
                                modalData={analyticsData.data?.conversations}
                                modalDataSharedColumn="conversationId"
                                modalConfig={AnalyticsConfig_liveagentsessiondetails}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
            <Box className="bb-mb2"></Box>
        </Container>
    );
};

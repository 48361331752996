import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback, useMemo } from "react";

import useDataClient from "../../axios/dataClient";
import { TextField } from "../../material/TextField";
import { Select } from "../../material/Select";

import { CustomerUser, CustomerUserType, Team } from "../../services/model/users/customerUser";
import { UserEditObject, UserSchema as userSchema } from "../../yup-schemas/UserSchema";

interface userEditDialogProps {
    dialogOpen: boolean;
    teams: Team[];
    user: CustomerUser | undefined;
    onSuccess: (newUser: CustomerUser) => void;
    onCancel: () => void;
}

export const UserEditDialog = ({ dialogOpen, teams, user, onSuccess, onCancel }: userEditDialogProps) => {

    const { put } = useDataClient();
    const saveEdit = useCallback(async (formUser: UserEditObject) => {
        // We need this type coersion here because results from the form can be messy
        if ((formUser.maximumChats as any) === "") {
            formUser.maximumChats = undefined;
        }

        const completeUser = { ...user, maximumChats: formUser.maximumChats, teamIds: formUser.teamIds, displayName: formUser.displayName };

        const savedUser = await put<CustomerUser>("api/user", completeUser);
        onSuccess(savedUser);
    }, [onSuccess, put, user]);

    const rolesString = useMemo(() => {
        if (user === undefined) {
            return "";
        }
        const userRoles = user.roles.map(role => role.name);
        return userRoles.join(", ");
    }, [user]);

    if (user === undefined) {
        return <></>;
    }

    return (<Dialog open={dialogOpen}>
        <DialogTitle className="bb-modal-header">
            <Typography variant="h3" className="bb-mb-0">{user.firstName} {user.lastName}</Typography>
        </DialogTitle>
        <Formik<UserEditObject>
            initialValues={{ ...user, rolesString }}
            onSubmit={saveEdit}
            validationSchema={userSchema}>
            <Form>
                <DialogContent className="bb-global-modal">
                    {user.type === CustomerUserType.Local ? <TextField name="firstName" label="First Name" disabled></TextField> : <></>}
                    {user.type === CustomerUserType.Local ? <TextField name="lastName" label="Last Name" disabled></TextField> : <></>}
                    <TextField name="email" label="Email" disabled></TextField>
                    <TextField name="rolesString" label="Roles" disabled></TextField>
                    <TextField
                        name="displayName"
                        label="Display Name"
                    ></TextField>
                    <TextField
                        name="maximumChats"
                        placeholder="Maximum Chats"
                        label="Maximum Chats"
                        required={false}
                    ></TextField>
                    <Select
                        name="teamIds"
                        label="Team"
                        multiple={true}
                        sx={{ width: "100%" }}
                    >
                        {teams.map(team => <MenuItem key={team.id} value={team.id}>{team.displayName}</MenuItem>)}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button className="bb-ml-auto" variant="contained" type="submit">Save</Button>
                </DialogActions>
            </Form>
        </Formik>
    </Dialog>);
};

import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';

interface TextEditorProps {
    value: string;
    onChange: (value: string) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({ value, onChange }) => {
    const [editorValue, setEditorValue] = useState(value);

    useEffect(() => {
        setEditorValue(value);
    }, [value]);

    const handleChange = (content: string) => {
        setEditorValue(content);
        onChange(content);
    };

    const modules = {
        toolbar: [
            [{ 'header': [2, 3, 4, 5, false] }], // Adds H2, H3, H4 & Normal to the tool bar
            ['bold', 'italic', 'underline'], // Adds Bold, Italic, and Underline
            [{ 'list': 'bullet' }], // Adds Bullet Points
            ['link'], // Adds Link and Image
            ['clean'] // Adds the ability to wipe formatting
        ]
    };

    const formats = [
        'header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link'
    ];

    return (
        <div className="editor-container">
            <ReactQuill theme="snow" value={editorValue} onChange={handleChange} modules={modules} formats={formats} />
        </div>
    );
};

export { TextEditor };

import { useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { PageConversationData } from "../../../services/model/livechat/dashboard/pageConversationData";
import { DateDisplay } from "../../shared/DateDisplay";
import { LoadingCard } from "../../shared/LoadingCard";

interface PageConversationsProps {
    isLoading: boolean;
    isVisible: boolean;
    pageConversationData: PageConversationData[];
    path: string;
}

export const PageConversations = (props: PageConversationsProps) => {
    const renderDate = useCallback((params: GridRenderCellParams<any, any, any>) => {
        const date = new Date(params.value);
        return (<DateDisplay value={date} timeStyle="medium" />);
    }, []);

    const columns: GridColDef[] = [
        { field: "siteTime", headerName: "Site Time", flex: 1, renderCell: renderDate },
        { field: "pageTime", headerName: "Page Time", flex: 1, renderCell: renderDate },
        { field: "userName", headerName: "Display Name", flex: 1 }
    ];

    if (!props.isVisible) {
        return (<></>);
    }

    const title = "Active Users on " + props.path;

    if (props.isLoading) {
        return (<LoadingCard title={title} />);
    }

    return (
        <Box className="bb-mt-2 bb-mb-2 bb-p-2" sx={{ background: "#fff", border: "1px solid #ddd", borderRadius: "7px" }}>
            <Typography variant="h3">{title}</Typography>
            <DataGrid
                autoHeight
                rows={props.pageConversationData}
                columns={columns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "conversations", sort: "desc" }]
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: 5
                        }
                    }
                }}
                disableRowSelectionOnClick
                pageSizeOptions={[5, 10, 25, 50]} />
        </Box>
    );
};

/* eslint-disable react/jsx-no-bind */

import { Box, Grid, Container, Typography, Alert, CircularProgress } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useState, useEffect } from "react";

import { useQuery } from "react-query";

import { AnalyticsKpiGrid } from "../Statistics/AnalyticsKpiGrid";

import useDataClient from "../../axios/dataClient";

import { getAnalyticsKpiData } from "../../services/api/StatisticsService";

import { getFrontends } from "../../services/api/FrontendService";

import { AnalyticsLineChart } from "./AnalyticsLineChart";

import {
    AnalyticsConfig_kpidetail,
    AnalyticsDataGrid,
} from "./AnalyticsDataGrid";

import {
    MapDisplayName, FilterKPIData
} from "./AnalyticsHelpers";

export const AnalyticsKpi = () => {

    const [isProductionDomain] = useState(true);
    const [kpiSelector, setkpiSelector] = useState(AnalyticsConfig_kpidetail.columns[2].field);
    const [appSelector, setAppSelector] = useState('[ALL]');
    const [kpiSelectorName, setkpiSelectorName] = useState(AnalyticsConfig_kpidetail.columns[2].headerName);

    const [lastDays] = useState("31536000");
    const { get } = useDataClient();

    useEffect(() => {
        document.title = 'Beebot AI - Insights';
    },[]);

    const handleChangeKpi = (event: SelectChangeEvent) => {
        const name = AnalyticsConfig_kpidetail.columns.slice(2).find(
            item => item.field === event.target.value as string);
        setkpiSelector(event.target.value as string);
        if (name) {
            const postfix = event.target.value.includes('_pc') ? ' (% Users)' : '';
            setkpiSelectorName(name.headerName as string + postfix);
        }
    };

    const handleChangeApp = (event: SelectChangeEvent) => {
        setAppSelector(event.target.value as string);
    };

    const analyticsData = useQuery([lastDays, isProductionDomain], () => getAnalyticsKpiData(get, lastDays, isProductionDomain));

    const frontEndData = useQuery([], () => getFrontends(get));

    if (!analyticsData || frontEndData.isLoading) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }
    if (!analyticsData.data ) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }
    if (!analyticsData.data?.["005-cards-customer-ai-kpi"] ) {
        return  (<Grid item xs={12} md={6}>
            <Alert className="bb-title-info bb-mb-2" severity="info">
            No Key Performance Indicators Available Right Now.
            </Alert>
        </Grid>);
    }

    let dataDisplayName = FilterKPIData(analyticsData.data, frontEndData.data);

    dataDisplayName = MapDisplayName(dataDisplayName, frontEndData.data, ['customerBot']);

    const kpiMenuItems = AnalyticsConfig_kpidetail.columns.slice(2).map(item => (
        <MenuItem key={item.field} value={item.field}>{item.headerName}</MenuItem>
    ));

    const appMenuItems = frontEndData.data?.map(item => (
        <MenuItem key={item.id} value={item.id}>{item.displayName}</MenuItem>
    ));
    if (frontEndData.data?.length !== 1) {
        appMenuItems?.push(<MenuItem key={'[ALL]'} value={'[ALL]'}>{'All'}</MenuItem>);
    }

    return (
        <Container sx={{ marginBottom: "5rem" }} className="bb-analytics-page">
            <Box className="bb-title-bar bb-mt-4">
                <Typography variant="h2" className="bb-mb-2 bb-p0">KPI Overview</Typography>
            </Box>
            <Grid className="bb-mb-4" container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} md={6}>
                    <Alert className="bb-title-info bb-mb-2" severity="info">
                        Check out your Key Performance Indicators.
                    </Alert>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                    <Box className="bb-analytics-dom">
                        <FormLabel component="legend">Included Domains</FormLabel>
                        <FormGroup>
                            <Tooltip title="Include Non-Production Domain Data?">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isProductionDomain}
                                            size="small"
                                            disabled={true}
                                            onChange={handleChangePD}
                                        />
                                    }
                                    label={isProductionDomain ? "Production Only" : "All"}
                                    labelPlacement='end'
                                />
                            </Tooltip>
                        </FormGroup>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="analytics-simple-select-label">Date Range</InputLabel>
                        <Select
                            labelId="analytics-simple-select-label"
                            id="analytics-simple-select"
                            value={lastDays}
                            label="Date Range"
                            disabled={true}
                            onChange={handleChange}
                        >
                            <MenuItem value={"31536000"}>Last 12 Months</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="analytics-simple-select-kpi-label">KPI Selector</InputLabel>
                        <Select
                            labelId="analytics-simple-select-label"
                            id="analytics-simple-select"
                            value={kpiSelector}
                            label="Date Range"
                            onChange={handleChangeKpi}
                        >{kpiMenuItems}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="analytics-simple-select-label">App Selector</InputLabel>
                        <Select
                            labelId="analytics-simple-select-label"
                            id="analytics-simple-select"
                            value={appSelector}
                            label="Date Range"
                            onChange={handleChangeApp}
                        >{appMenuItems}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box className="bb-mb-4 animated animatedFadeInUp fadeInUp4">
                <AnalyticsKpiGrid loading={analyticsData.isLoading} data={analyticsData.data} app={appSelector} />
            </Box>
            {analyticsData.isLoading ? null && analyticsData.data : (
                <Box className="bb-ui-box animated animatedFadeInUp fadeInUp5 bb-flex bb-flex-column">
                    <Typography variant="h3" component="h3" className="bb-tac">KPIs</Typography>
                    <Box>
                        <AnalyticsLineChart
                            data={dataDisplayName["005-summary-customer-ai-kpi"]}
                            rowFilters={appSelector}
                            valueCol={kpiSelector}
                            formatPc={kpiSelector.includes('_pc')}
                            valueColName={kpiSelectorName}
                        />
                    </Box>
                </Box>
            )}
            <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp7">
                <Grid item xs={12}>
                    {analyticsData.isLoading ? null : (
                        <>
                            <AnalyticsDataGrid
                                data={dataDisplayName["005-summary-customer-ai-kpi"]}
                                config={AnalyticsConfig_kpidetail}
                                title="KPI By Month"

                            />
                        </>
                    )}
                </Grid>
            </Grid>
            <Box className="bb-mb2"></Box>
        </Container>
    );
};

import React from 'react';
import { useFavorites } from './FavoriteContext';
import { IconButton, Tooltip } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

interface FavoriteButtonProps {
    page: string;
    displayName: string;
    displayModule: string;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = ({ page, displayName, displayModule }) => {
    const { favorites, addFavorite, removeFavorite } = useFavorites();

    const isFavorite = favorites.some(fav => fav.url === page);

    const handleToggleFavorite = () => {
        if (isFavorite) {
            removeFavorite(page);
        } else {
            addFavorite({ url: page, title: displayName, subtitle: displayModule });
        }
    };

    return (
        <Tooltip title={isFavorite ? 'Remove from Dashboard' : 'Pin to Dashboard'}>
            <IconButton onClick={handleToggleFavorite}>
                {isFavorite ? <PushPinIcon /> : <PushPinOutlinedIcon />}
            </IconButton>
        </Tooltip>
    );
};

import React from "react";
import * as msal from "@azure/msal-browser";

import { AzureB2CConfiguration } from "./authentication/authConfig";
import { AgentState } from "./components/livechat/agentState";

interface msalConfig {
    msalClientApp: undefined | msal.PublicClientApplication;
    msalConfig: undefined | AzureB2CConfiguration;
}

export interface UserInformation {
    isLiveOperator: boolean;
    isAdmin: boolean;
    isLiveAgentAdmin: boolean;
    isApplicationContributor: boolean;
    isApplicationAdmin: boolean;
    isCaiContributor: boolean;
    isCaiAdmin: boolean;
    isRpaContributor: boolean;
    isRpaAdmin: boolean;
    isMediaContributor: boolean;
    isDataContributor: boolean;
    isDataAdmin: boolean;
    displayName: string | undefined;
    isChatting: AgentState;
    liveAgentTeams: string[];
    sub: string; // this is the subject id from the token, we use it as a user id
}

export interface AppContext {
    msalConfig: msalConfig;
    user: UserInformation;
    isAuthenticated: boolean;
    sidebarOpen: boolean;
    setSidebarOpen: (open: boolean) => void;
    updateWithTokenData?: () => void;
    updateLiveAgentStatus: (status: AgentState) => void;
    searchString: string;
    setSearchString: (searchString: string) => void;
    updateDisplayName: (displayName: string) => void;
}

export const MemberAppContext = React.createContext<AppContext>({
    msalConfig: {
        msalClientApp: undefined,
        msalConfig: undefined
    },
    isAuthenticated: false,
    user: {
        isLiveOperator: false,
        isAdmin: false,
        isLiveAgentAdmin: false,
        isApplicationContributor: false,
        isApplicationAdmin: false,
        isCaiContributor: false,
        isCaiAdmin: false,
        isRpaContributor: false,
        isRpaAdmin: false,
        isMediaContributor: false,
        isDataContributor: false,
        isDataAdmin: false,
        displayName: undefined,
        isChatting: AgentState.DISCONNECTED,
        liveAgentTeams: [],
        sub: ""
    },
    setSidebarOpen: () => undefined,
    sidebarOpen: true,
    updateWithTokenData: () => undefined,
    updateLiveAgentStatus: () => undefined,
    searchString: "",
    setSearchString: () => undefined,
    updateDisplayName: () => undefined
});

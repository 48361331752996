import React, { useCallback, useEffect } from 'react';

import { TextField, Box, Button, styled, Grid, Modal, useTheme, CircularProgress, Typography } from '@mui/material';

import CloseIcon from "@mui/icons-material/Close";

import { WidgetProps } from '@rjsf/utils';

import { FileFolderView, FileFolderInfo } from "./FileFolderView";

import { SearchField } from "../Search/SearchField";

const MediaLibraryWidget = (props: WidgetProps) => {
    const theme = useTheme();

    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [fileFolderInfo, setFileFolderInfo] = React.useState<FileFolderInfo>();
    const [mediaSearchString, setMediaSearchString] = React.useState<string>();
    const [requiredHeight, setRequiredHeight] = React.useState<number>();
    const [requiredWidth, setRequiredWidth] = React.useState<number>();

    useEffect(() => {
        document.title = 'Beebot AI - Media Library';
    },[]);

    const ModalBox = styled(Box)(() => ({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        padding: 15,
        p: 4,
        overflow: "auto",
        maxHeight: "60%",
    }));

    const callSearch = useCallback((searchString?: string) => {
        setMediaSearchString(searchString);
    }, [setMediaSearchString]);

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        props.onChange(newValue);
    }, [props]);

    const handleModalClose = React.useCallback(() => {
        return;
    }, []);

    const handleModalCancel = React.useCallback((): void => {
        setOpenEditModal(false);
    }, []);

    const handleFileSelect = React.useCallback((fileUrl: string): void => {
        props.onChange(encodeURI(fileUrl));
        setOpenEditModal(false);
    }, [props]);

    const handleModalOpen = React.useCallback(() => {
        setOpenEditModal(true);
    }, []);

    const handleFolderSelection = React.useCallback((fullPath?: string): void => {
        setFileFolderInfo(getInfoFromPath(fullPath));
    }, []);

    useEffect(() => {
        setMediaSearchString("");
        setFileFolderInfo(getInfoFromPath());
    }, []);

    useEffect(() => {
        if (!isNaN(props.schema?.height) &&
            !isNaN(props.schema?.width)) {

            setRequiredHeight(Number(props.schema?.height));
            setRequiredWidth(Number(props.schema?.width));
        }
    }, [props.schema?.height, props.schema?.width]);

    const getInfoFromPath = (fullPath?: string): FileFolderInfo => {
        if (fullPath === undefined) {
            return { fullPath, currentPath: "", parentPath: "", name: "", isPublic: true };
        }

        const segments = fullPath.split("/");
        if (segments.length === 0) {
            return { fullPath, currentPath: "", parentPath: "", name: "", isPublic: true };
        }

        return {
            fullPath,
            currentPath: fullPath,
            parentPath: segments.map(segment => decodeURI(segment)).slice(0, segments.length - 1).join("/"),
            name: decodeURI(segments[segments.length - 1]),
            isPublic: true
        };
    };

    if (!fileFolderInfo) {
        return (<div className="bb-tac"><CircularProgress /></div>);
    }

    return (
        <>
            <Modal
                open={openEditModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
            >
                <ModalBox className="bb-edit-page-modal">
                    <>
                        <Box className="bb-flex bb-p-3 bb-align-items-center bb-edit-page-modal-header">
                            <Typography variant='h2' className="bb-mb-0" sx={{ marginLeft: ".5rem" }}>
                                Media Library: {fileFolderInfo.name || "Public"}
                            </Typography>
                            {requiredHeight && requiredWidth &&
                                <>
                                    <Typography variant='h5' className="bb-mb-0" sx={{ marginLeft: "1rem" }}>
                                        [Required Image Size -{'>'} Height: {requiredHeight} px
                                    </Typography>
                                    <Typography variant='h5' className="bb-mb-0" sx={{ marginLeft: ".5rem" }}>
                                        Width: {requiredWidth} px]
                                    </Typography>
                                </>
                            }
                            <Box className="bb-med-search">
                                <SearchField
                                    searchString={mediaSearchString}
                                    callSearch={callSearch}
                                />
                            </Box>
                            <Button className="bb-ml-auto" onClick={handleModalCancel} variant="text">
                                <CloseIcon></CloseIcon>
                            </Button>
                        </Box>
                        <Box className="bb-pe-modal-form-media">
                            <FileFolderView
                                fileFolderInfo={fileFolderInfo}
                                mediaSearchString={mediaSearchString}
                                handleFolderSelection={handleFolderSelection}
                                handleFileSelect={handleFileSelect}
                                handleModalCancel={handleModalCancel}
                                requiredHeight={requiredHeight}
                                requiredWidth={requiredWidth}
                            />
                        </Box>
                    </>
                </ModalBox>
            </Modal>
            <Grid container spacing={1} >
                <Grid item md={11 - (props.value ? 2 : 0)} xs={12}>
                    <TextField
                        id={props.id}
                        label="Image"
                        variant="outlined"
                        value={props.value}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                {props.value ? (
                    <Grid item md={2} xs={12} className="bb-media-thumb-cont">
                        <img src={props.value} className="bb-media-thumb-picker bb-width-100 bb-border-radius" alt="Your Image"/>
                    </Grid>
                ) : (<span></span>)}
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleModalOpen}
                    sx={{ minWidth: "90px", margin: ".5rem .5rem 1rem .5rem", }}>
                    Select
                </Button>
            </Grid>
        </>
    );
};

export { MediaLibraryWidget };

import { useCallback, useState, useEffect } from "react";

import { Box, CircularProgress, Grid, Container, Alert } from "@mui/material";

import { useAsync } from "../../useAsync";

import { getFrontends } from "../../services/api/FrontendService";

import { FrontendListModel } from "../../services/model/frontend";

import useDataClient from "../../axios/dataClient";

import { ApplicationCard } from "./ApplicationCard";

export const Applications = () => {

    const { get, post } = useDataClient();
    const [apps, setApps] = useState<FrontendListModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document.title = 'Beebot AI - Applications';
    },[]);

    const loadApps = useCallback(async () => {
        setIsLoading(true);
        const data = await getFrontends(get);
        setIsLoading(false);
        return data;
    }, [get]);

    useAsync(loadApps, setApps, [get]);

    const onIsEnabledChange = useCallback(async (appId: string, isEnabled: boolean) => {
        setApps(appz => appz.map(app => app.id === appId ? { ...app, isActive: isEnabled } : app));

        await post(`api/frontend/apps/${appId}/setEnabled`, { enabled: isEnabled });
    }, [post]);

    const renderCard = useCallback((frontend: FrontendListModel) => {
        return (<Grid item xs={12} sm={6} md={6} lg={4} key={frontend.id} >
            <ApplicationCard
                displayName={frontend.displayName}
                id={frontend.id} isActive={frontend.isActive}
                handleIsEnabledChange={onIsEnabledChange}
                isEnabledToggleDisabled={false}
                visitorsInTheLast24H={frontend.visitorsInTheLast24H}
                visitorsInTheLast30D={frontend.visitorsInTheLast30D}
            />
        </Grid>);
    }, [onIsEnabledChange]);

    return (
        <Container>
            <Box className="bb-title-bar"><h2>Your Apps</h2></Box>
            <Alert className="bb-title-info" severity="info">Select one of your apps to manage, edit and view analytics.</Alert>
            <Grid className="bb-apps"
                container
                spacing={2}
                alignItems="stretch"
                alignContent="left">
                {isLoading ? <CircularProgress /> : apps?.map(renderCard)}
            </Grid>
        </Container>
    );
};

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Typography } from "@mui/material";
import { useCallback, useEffect, useState, useContext } from "react";

import { Refresh, Visibility, VisibilityOff } from "@mui/icons-material";

import useDataClient from "../../../axios/dataClient";

import { ChatHubContext } from "../chatHubContext";

import { useAsync } from "../../../useAsync";

import { Team } from "../../../services/model/users/customerUser";

import { MemberAppContext } from "../../../MemberAppContext";

import { LiveAgentQueueItem } from "./liveAgentQueueItem";

import { QueueItemSelectedDialog } from "./QueueItemSelectedDialog";

import { QueueItemDeletingDialog } from "./QueueItemDeletingDialog";

import { TeamsSelect } from "./teamsSelect";

import { QueueItemForwardDialog } from "./QueueItemForwardDialog";

import { QueueItem } from ".";

interface QueueDialogProps {
    open: boolean;
    onClose: () => void;
    onItemSelected: () => void;
    chatDequeuedCallback: (item: LiveAgentQueueItem) => void;
    updateQueueItemCount: (count: number) => void;
}

export const QueueDialog = ({ open, onClose, onItemSelected, chatDequeuedCallback, updateQueueItemCount }: QueueDialogProps) => {

    const [teams, setTeams] = useState<Team[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
    const [queuedMessages, setQueuedMessages] = useState<LiveAgentQueueItem[]>([]);
    const [filteredQueuedMessages, setFilteredQueuedMessages] = useState<LiveAgentQueueItem[]>([]);
    const [queueItemSelected, setQueueItemSelected] = useState<LiveAgentQueueItem | null>(null);
    const [queueVisible, setQueueVisible] = useState<boolean>(true);
    const [choosingSelected, setChoosingSelected] = useState<boolean>(false);
    const [deletingSelected, setDeletingSelected] = useState<boolean>(false);
    const [forwardingSelected, setForwardingSelected] = useState<boolean>(false);
    const { get, put, deleteRequest } = useDataClient();
    const { registerMessageQueuedCallback, registerMessageDequeuedCallback } = useContext(ChatHubContext);
    const { user: { liveAgentTeams, isLiveAgentAdmin } } = useContext(MemberAppContext);

    const fetchQueuedMessages = useCallback(
        async () => {
            const allQueueItems = await get<LiveAgentQueueItem[]>("api/queue");
            if (isLiveAgentAdmin) {
                return allQueueItems;
            }
            return allQueueItems.filter(queueItem => queueItem.team ? liveAgentTeams.some(team => (queueItem.team as string).startsWith(team)) : true);
        },
        [get, liveAgentTeams, isLiveAgentAdmin],
    );

    const fetchQueueVisibility = useCallback(async () => {
        return get<boolean>("api/queue/queueVisibility");
    }, [get]);

    const onQueueItemLocked = useCallback(async (item: LiveAgentQueueItem) => {
        await get(`api/queue/lockChat/${item.chatId}`);
        setQueueItemSelected(item);
        setChoosingSelected(true);
    }, [get]);

    const fetchTeams = useCallback(
        async () => {
            const promise = get<Team[]>("api/team");
            const fetchedTeams = await promise;
            // I'm sorry about this. See the comment in Common/Models/CustomerUser/CustomerUserListViewModel.cs for details, but basically
            // the users token only has the first eight characters of the users teams
            return fetchedTeams.map(team => ({ ...team, id: team.id.substring(0, 8) }));
        }
        , [get]);

    const onQueueItemLockCancel = useCallback(async () => {
        if (queueItemSelected === null) {
            Error("queue item lock cancelled but no item selected");
            return;
        }

        await get(`api/queue/unlockChat/${queueItemSelected.chatId}`);
        setQueueItemSelected(null);
        setChoosingSelected(false);
    }, [get, queueItemSelected]);

    const onQueueItemSelected = useCallback(async () => {
        if (queueItemSelected === null) {
            Error("Cannot select queue item that doesn't exist");
            return;
        }

        await get(`api/queue/selectChat/${queueItemSelected?.botName}/${queueItemSelected?.chatId}`).catch(async (err) => {
            const current = await fetchQueuedMessages();
            setQueuedMessages(current);
            throw err;
        });
        chatDequeuedCallback(queueItemSelected);
        setQueueItemSelected(null);
        setChoosingSelected(false);
        setQueuedMessages(messages => messages.filter(m => m.chatId !== queueItemSelected.chatId));
        onItemSelected();
    }, [get, queueItemSelected, onItemSelected, chatDequeuedCallback, fetchQueuedMessages, setQueuedMessages]);

    const onQueueItemDelete = useCallback(async (item: LiveAgentQueueItem) => {
        await get(`api/queue/lockChat/${item.chatId}`);
        setQueueItemSelected(item);
        setDeletingSelected(true);
    }, [get]);

    const onQueueItemDeleteCancel = useCallback(async () => {
        if (queueItemSelected === null) {
            Error("queue item lock cancelled but no item selected");
            return;
        }

        await get(`api/queue/unlockChat/${queueItemSelected.chatId}`);
        setDeletingSelected(false);
    }, [get, queueItemSelected]);

    const onQueueItemDeleteConfirm = useCallback(async () => {
        if (queueItemSelected === null) {
            Error("Cannot delete queue item that doesn't exist");
            return;
        }

        await deleteRequest(`api/queue/deleteChat/${queueItemSelected.chatId}/${queueItemSelected.botName}`);
        setQueueItemSelected(null);
        setQueuedMessages(messages => messages.filter(m => m.chatId !== queueItemSelected.chatId));
        setDeletingSelected(false);
    }, [deleteRequest, queueItemSelected]);

    const onQueueItemForward = useCallback(async (item: LiveAgentQueueItem) => {
        setQueueItemSelected(item);
        setForwardingSelected(true);
    }, []);

    const onQueueItemForwardCancel = useCallback(() => {
        setForwardingSelected(false);
    }, []);

    const onQueueItemForwardCofirm = useCallback(() => {
        setQueueItemSelected(null);
        setForwardingSelected(false);
    }, []);

    const messageQueuedCallback = useCallback((item: LiveAgentQueueItem) => {
        if (isLiveAgentAdmin || item.team === undefined || liveAgentTeams.some(team => item.team?.startsWith(team))) {
            setQueuedMessages(messages => [...messages, item]);
        }
    }, [isLiveAgentAdmin, liveAgentTeams]);

    const messageDequeuedCallback = useCallback((customerId: string, chatId: string) => {
        setQueuedMessages(messages => messages.filter(m => m.chatId !== chatId));
    }, []);

    const refreshQueue = useCallback(async () => {
        const current = await fetchQueuedMessages();
        setQueuedMessages(current);
    }, [fetchQueuedMessages, setQueuedMessages]);

    const toggleQueue = useCallback(async () => {
        await put(`api/queue/setVisibility/${!queueVisible}`);
        setQueueVisible(visible => !visible);
    }, [put, queueVisible]);

    const visibilityToggle = useCallback(() => {
        if (queueVisible) {
            return (
                <Tooltip title="Disable queue">
                    <IconButton sx={{ color: "white" }} onClick={toggleQueue} name="Disable queue">
                        <Visibility></Visibility>
                    </IconButton>
                </Tooltip>);
        }
        else {
            return (
                <Tooltip title="Enable queue">
                    <IconButton sx={{ color: "white" }} onClick={toggleQueue} name="Enable queue">
                        <VisibilityOff></VisibilityOff>
                    </IconButton>
                </Tooltip>);
        }
    }, [queueVisible, toggleQueue]);

    const visibileWarning = useCallback(() => {
        if (queuedMessages.length === 0)
        {
            return <Typography>{queueVisible ? "Nothing yet!" : "Enable the queue to see new messages"}</Typography>;
        }
    }, [queueVisible, queuedMessages]);

    useAsync(
        fetchQueuedMessages,
        setQueuedMessages,
        []);

    useAsync(
        fetchQueueVisibility,
        setQueueVisible,
        []);

    useAsync(
        fetchTeams,
        setTeams,
        []);

    useEffect(() => {
        registerMessageQueuedCallback(messageQueuedCallback);
    }, [messageQueuedCallback, registerMessageQueuedCallback]);

    useEffect(() => {
        updateQueueItemCount(queuedMessages.length);
    }, [updateQueueItemCount, queuedMessages]);

    useEffect(() => {
        registerMessageDequeuedCallback(messageDequeuedCallback);
    }, [registerMessageDequeuedCallback, messageDequeuedCallback]);

    useEffect(() => {
        const newQueue = queuedMessages.filter(queueItem =>
            queueItem.team === undefined ||
            selectedTeams.some(team => queueItem.team?.startsWith(team) ?? true));
        setFilteredQueuedMessages(newQueue);
    }, [selectedTeams, queuedMessages]);

    return (<><Dialog open={open} >
        <DialogTitle className="queue-dialog-title bb-align-items-center">
            <Typography sx={{ display: "flex" }} variant="h3" className="bb-mb-0">Queues</Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            {visibilityToggle()}
            <IconButton sx={{ color: "white" }} onClick={refreshQueue}>
                <Refresh />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "1em" }}>
                <TeamsSelect teams={teams} selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} />
            </Box>
            <Grid
                className="queue-grid"
                container
                spacing={2}
                marginTop="1rem">
                {filteredQueuedMessages.map(message =>
                    (<Grid
                        item
                        xs={12}
                        sm={6}
                        key={message.chatId}>
                        <QueueItem
                            teams={teams}
                            item={message}
                            selectQueueItem={onQueueItemLocked}
                            deleteQueueItem={onQueueItemDelete}
                            forwardQueueItem={onQueueItemForward} />
                    </Grid>))}
            </Grid>
            <Box sx={{ fontStyle: "italic" }}>
                {visibileWarning()}
            </Box>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
    <QueueItemSelectedDialog open={choosingSelected} item={queueItemSelected} onCancel={onQueueItemLockCancel} onSelect={onQueueItemSelected} />
    <QueueItemDeletingDialog open={deletingSelected} item={queueItemSelected} onCancel={onQueueItemDeleteCancel} onConfirm={onQueueItemDeleteConfirm} />
    <QueueItemForwardDialog
        open={forwardingSelected}
        item={queueItemSelected}
        onCancel={onQueueItemForwardCancel}
        onSuccessfullForward={onQueueItemForwardCofirm} />
    </>);
};

import * as React from "react";
import { Grid, Card, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface BBAccordionProps {
    panel: any;
}

export const BBAccordion: React.FC<BBAccordionProps> = ({ panel }) => {
    const createMarkup = (html: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const links = doc.querySelectorAll('a');
        links.forEach(link => {
            const span = document.createElement('span');
            span.innerHTML = link.innerHTML;
            link.parentNode?.replaceChild(span, link);
        });
        return { __html: doc.body.innerHTML };
    };

    return (
        <Box>
            {panel?.accData?.map((data: any, index: number) => (
                <Grid item xs className={` ${index === 0 ? '' : ''}`} key={index}>
                    {index === 0 && (
                        <>
                            <img src={panel.imgUrl} className="bb-pe-img bb-width-100" />
                            <Typography variant="h3" className="bb-tal bb-mt-1 bb-mb-1">{panel.panelHeading}</Typography>
                            <Typography variant="body1">{panel.panelPara}</Typography>
                        </>
                    )}
                    <Card className="bb-mb-2 bb-tal">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                sx={{ borderBottom: "1px solid #eee", background: "#eee", padding: ".25rem 1rem" }}
                            >
                                <Typography variant="h6">{data.heading}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "1rem 1rem 1rem 1rem" }} className="bb-accordion-content bb-tal">
                                <div dangerouslySetInnerHTML={createMarkup(data.body)} />
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Grid>
            ))}
        </Box>
    );
};

import { useState, useCallback, useContext, useEffect } from "react";
import { Box, Button, CircularProgress, Grid, Skeleton, Container, Alert, Tooltip } from "@mui/material";

import useDataClient from "../../axios/dataClient";

import { MemberAppContext } from "../../MemberAppContext";

import { useAsync } from "../../useAsync";

import { RpaCard } from "./RpaCard";

interface RpaListModel {
    id: string;
    displayName: string;
    isEnabled: boolean;
    successfulRuns: number;
    failedRuns: number;
    isEnabledChanging: boolean;
}

interface GetRpasResult {
    rpas: RpaListModel[];
    continuationToken: string;
}

export const RpasPage = () => {
    const filter = ("");

    const pageSize = 20;
    const [rpas, setRpas] = useState<RpaListModel[]>([]);
    const [continuationToken, setContinuationToken] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const { searchString } = useContext(MemberAppContext);

    const { get, post } = useDataClient();

    useEffect(() => {
        document.title = 'Beebot AI - Automations';
    },[]);

    const loadResults = useCallback(async () => {
        setIsLoading(true);
        const response = await get(`/api/rpas?pageSize=${pageSize}` + filter) as GetRpasResult;
        setIsLoading(false);
        setContinuationToken(response.continuationToken);
        return response.rpas;
    }, [filter, get]);

    useAsync(loadResults, setRpas, []);

    const loadMore = useCallback(async () => {
        if (!isLoading && continuationToken) {
            setIsLoadingMore(true);
            const response = await get(`/api/rpas?pageSize=${pageSize}&continuationToken=${continuationToken}` + filter) as GetRpasResult;
            setIsLoadingMore(false);
            setRpas(state => [...state, ...response.rpas]);
            setContinuationToken(response.continuationToken);
        }
    }, [isLoading, continuationToken, get, filter]);

    const search = useCallback(async () => {
        const searchForUri = encodeURIComponent(searchString);
        const queryString = `pageSize=${pageSize}&searchString=${searchForUri}` + filter;
        const response = await get(`/api/rpas?${queryString}`) as GetRpasResult;
        setRpas(response.rpas);
    }, [get, searchString, filter]);

    useEffect(() => {
        search();
    }, [search]);

    const handleIsEnabledChange = useCallback(async (logicAppId: string, isEnabled: boolean) => {
        const updateRpa = (transform: ((old: RpaListModel) => RpaListModel)) => {
            setRpas(state => {
                const i = rpas.findIndex(rpa => rpa.id === logicAppId);
                const nextState = [...state];
                nextState[i] = transform({ ...nextState[i] });
                return nextState;
            });
        };

        updateRpa(x => ({ ...x, isEnabledChanging: true }));

        try {
            const newRpa = await post<RpaListModel>(`api/rpas/${logicAppId}/setEnabled`, { enabled: isEnabled });
            updateRpa(() => newRpa);
        } catch (err) {
            updateRpa(x => ({ ...x, isEnabledChanging: false }));
            throw err;
        }
    }, [rpas, post]);

    return (
        <Container>
            <Box className="bb-title-bar"><h2>Automations</h2></Box>
            <Alert className="bb-title-info" severity="info">Automations are automated tasks you can manage those here.</Alert>
            <Grid
                container
                spacing={3}
                alignItems="stretch"
                alignContent="center">
                {!isLoading ? (
                    <>
                        {rpas.map((c) => (
                            <Grid item xs={12} sm={6} md={5} lg={4} xl={4} key={c.id}>
                                <Tooltip title="Enable / disable your automated tasks">
                                    <RpaCard
                                        id={c.id}
                                        displayName={c.displayName}
                                        isEnabled={c.isEnabled}
                                        successfulRuns={c.successfulRuns}
                                        failedRuns={c.failedRuns}
                                        handleIsEnabledChange={handleIsEnabledChange}
                                        isEnableToggleDisabled={c.isEnabledChanging} />
                                </Tooltip>
                            </Grid>
                        ))}

                        <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
                            {!isLoading && !isLoadingMore
                                ? (<Button onClick={loadMore}>Load more</Button>)
                                : (<Skeleton variant="rectangular" />)}
                        </Grid>
                    </>
                ) : (
                    <div className="bb-width-100 bb-tac"><CircularProgress /></div>
                )}
            </Grid>
        </Container>);
};

import { useParams } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useState, useEffect } from "react";
import useDataClient from "../../axios/dataClient";
import { getApp } from "../../services/api/FrontendService";
import { AppViewModel } from "../../services/model/apps/AppViewModel";
import { getStagingUrl } from "../../services/helper/regex";

interface AppLinksProps {
    pageName?: string; // Optional prop for the page name
    smartPageName?: string; // Optional prop for the smart page name
}

export const AppLinks: React.FC<AppLinksProps> = ({ pageName, smartPageName }) => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    }, [appId, get]);

    if (!appData) {
        return <Box className="bb-ml-auto bb-app-button-loader" sx={{ marginRight: "2rem" }}><CircularProgress /></Box>;
    }

    let stagingUrl = getStagingUrl(appData.url);
    let mainUrl = appData.url;

    if (pageName) {
        const separator = stagingUrl.includes('?') ? '&' : '?';
        stagingUrl = `${stagingUrl}${separator}page=${pageName}`;
        mainUrl = `${mainUrl}${separator}page=${pageName}`;
    }

    if (smartPageName) {
        const separator = stagingUrl.includes('?') ? '&' : '?';
        stagingUrl = `${stagingUrl}${separator}modal=${smartPageName}`;
        mainUrl = `${mainUrl}${separator}modal=${smartPageName}`;
    }

    return (
        <Box className="bb-ml-auto bb-align-items-center bb-flex">
            <Button
                startIcon={<OpenInNewIcon />}
                rel="noreferrer"
                className="bb-mr-1 animated animatedFadeInUp fadeInUp6"
                href={`https://${stagingUrl}`}
                target="_blank"
                size="small"
                variant="outlined"
                sx={{ padding: ".3rem .4rem" }}
            >
                Staging App
            </Button>
            <Button
                startIcon={<OpenInNewIcon />}
                rel="noreferrer"
                className="animated animatedFadeInUp fadeInUp7"
                href={`https://${mainUrl}`}
                target="_blank"
                size="small"
                variant="outlined"
                sx={{ padding: ".3rem .4rem" }}
            >
                Live App
            </Button>
        </Box>
    );
};

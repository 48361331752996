import { FC, useEffect, useMemo, useState } from "react";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { UiSchema } from "@rjsf/utils";

import { Grid, Box, Button, styled } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useParams } from "react-router-dom";

import { LinkTypeWidget } from "../customWidget/LinkTypeWidget";

import { MediaLibraryWidget } from "../customWidget/MediaLibrary/MediaLibraryWidget";

import { DbTableWidget } from "../customWidget/DbWidget/DbTableWidget";

import { DbColumnWidget } from "../customWidget/DbWidget/DbColumnWidget";

import { ColourPicker } from "../customWidget/ColourPicker/ColourPicker";

import { DescriptionWithLink } from "../customWidget/DescriptionWithLink";

import { HtmlCustomWidget } from "../customWidget/HtmlEditor/HtmlCustomWidget";

import { PageOptionsWidget } from "../customWidget/PageOptionsWidget";

import { usePageContext } from "./pagesContext";
import { getPageNames } from "../../../services/api/PagesService";

import useDataClient from "../../../axios/dataClient";

import { linksUiSchema, dbUiSchema, bannerDataUiSchema, panelTypeUiSchema, tabsUiSchema } from "./pageEditUiSchema";
import { PanelTypeDropDownWidget } from "../customWidget/Dropdowns/PanelTypeDropDown";
import { ColumnWidthDropDownWidget } from "../customWidget/Dropdowns/ColumnWidthDropDown";

export interface PageEditModalFormProps {
    onUpdate: (e: any) => void;
    onCancel: (e: any) => void;
    onError: (e: any) => void;
}

const ModalBox = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: 15,
    p: 4,
    overflow: "auto",
    maxHeight: "60%",
}));

export const PageEditModalForm: FC<PageEditModalFormProps> = ({ onUpdate, onError, onCancel }) => {
    const { appId } = useParams();
    const { currentFormData, currentSchema } = usePageContext();

    const [formData, setFormData] = useState(currentFormData);
    const [pageOptions, setPageOptions] = useState<string[]>([]);

    const { get } = useDataClient();

    useEffect(() => {
        const fetchOptions = async () => {
            if (appId) {
                const pages = await getPageNames<string[]>(get)(appId);

                const formatedPages = pages.map((page) => {
                    return page.replace(".json", "");
                });

                setPageOptions(formatedPages);
            }
        };

        fetchOptions();
    }, [appId, get]);

    const customWidgets = useMemo(() => ({
        LinkTypeWidget,
        MediaLibraryWidget,
        DbTableWidget,
        DbColumnWidget,
        PanelTypeDropDownWidget,
        ColumnWidthDropDownWidget,
        ColourPicker,
        PageOptionsWidget,
        HtmlCustomWidget
    }), []);

    const uiSchema: UiSchema = useMemo(() => ({
        'ui:submitButtonOptions': {
            norender: true
        },
        "ui:title": "",
        "linkThumbUrl": {
            "ui:widget": "MediaLibraryWidget"
        },
        "imgSrc": {
            "ui:widget": "MediaLibraryWidget"
        },
        "imgUrl": {
            "ui:widget": "MediaLibraryWidget"
        },
        "rteBody": {
            "ui:widget": "HtmlCustomWidget"
        },
        "html": {
            "ui:widget": "textarea",
            "ui:options": {
                "rows": 8
            }
        },
        "linkAction": {
            "ui:widget": "LinkTypeWidget"
        },
        "buttonBgColor": {
            "ui:widget": "ColourPicker"
        },
        "buttonTextColor": {
            "ui:widget": "ColourPicker"
        },
        "panelHeading": {
            "ui:style": {
                width: "50%",
            }
        },
        "links.ButtonBgColor": {
            "ui:widget": "ColourPicker"
        },
        "links.ButtonTextColor": {
            "ui:widget": "ColourPicker"
        },
        "rowSubTitle": {
            "ui:widget": "textarea"
        },
        "bbContent": {
            "ui:widget": "textarea"
        },
        "pageName": {
            "ui:options": {
                "size": "small"
            }
        },
        "descriptionLink": {
            "ui:field": "descriptionWithLink"
        },
        "bbPanelTypesList": { ...panelTypeUiSchema },
        "links": { ...linksUiSchema },
        "db": { ...dbUiSchema },
        "bannerData": { ...bannerDataUiSchema },
        "accData": { ...tabsUiSchema },
        "colSpan": {
            "ui:widget": "ColumnWidthDropDownWidget"
        },
        "slideCol": {
            "ui:style": {
                width: "50%",
            }
        },
        "directionArrows": {
            "ui:style": {
                width: "50%",
            }
        },
        "dataManagement": {
            "ui:style": {
                width: "50%",
            }
        },
        "linkType": {
            "ui:style": {
                width: "50%",
            }
        },
    }), []);

    const fields = useMemo(() => ({
        descriptionWithLink: DescriptionWithLink
    }), []);

    return (
        <ModalBox className="bb-edit-page-modal">
            <Box className="bb-flex bb-pe-modal-header">
                <h2>{currentSchema?.title}</h2>
                <Button className="bb-ml-auto" onClick={onCancel} variant="text">
                    <CloseIcon></CloseIcon>
                </Button>
            </Box>
            <Form
                id={currentSchema?.title}
                schema={{ ...currentSchema }}
                onChange={(e) => setFormData(e.formData)}
                formData={{ ...formData }}
                validator={validatorAjv8}
                onError={onError}
                widgets={customWidgets}
                onSubmit={onUpdate}
                uiSchema={uiSchema}
                fields={fields}
                className={"bb-pe-modal-form"}
                formContext={{ formData, pageOptions }}
            >
                <Box className="bb-pe-modal-footer">
                    <Grid container spacing={1} className="bb-flex bb-fixed">
                        <Grid item>
                            <Button onClick={onCancel} variant="text">
                                    Cancel
                            </Button>
                        </Grid>
                        <Grid item className="bb-ml-auto">
                            <Button variant="contained" type="submit">
                                    Update
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Form>
        </ModalBox>
    );
};

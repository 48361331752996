/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */

import { Box, Grid, Container, Typography, Alert, CircularProgress, FormLabel, FormGroup, FormControlLabel, Tooltip, Switch } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useState, useEffect, useMemo } from "react";

import { useQuery } from "react-query";

import { Breadcrumb } from "../applications/BreadCrumbComponent";

import { AnalyticsAppSummaryGrid } from "../Statistics/AnalyticsAppSummaryGrid";

import useDataClient from "../../axios/dataClient";

import { getAnalyticsData } from "../../services/api/StatisticsService";

import { getPages } from "../../services/api/PagesService";

import { PageFile } from "../../schemas/pages/schema";

import { getApp } from "../../services/api/FrontendService";

import { AppViewModel } from "../../services/model/apps/AppViewModel";

import { AnalyticsBarChart } from "./AnalyticsBarChart";

import { AnalyticsPieChart } from "./AnalyticsPieChart";

import { AnalyticsGroupBarChart } from "./AnalyticsGroupBarChart";

import { addReferenceData, transformProfileData, getUniqueProfileAttributes } from "./AnalyticsHelpers";

import { AppLinks } from "../applications/AppLinksComponent";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

import {
    AnalyticsConfig_pagedetail,
    AnalyticsConfig_clickdetail,
    AnalyticsDataGrid,
    AnalyticsConfig_searchdetail,
    AnalyticsConfig_feedbackdetail,
    AnalyticsConfig_preferencedetail
} from "./AnalyticsDataGrid";

import {
    AnalyticsConfig_sessiondetail,
} from "./AnalyticsDataGridModal";

interface Props {
    appId: string;
}

export const Analytics = ({ appId }: Props) => {

    const [isProductionDomain, setProductionDomain] = useState(true);

    const [lastDays, setLastDays] = useState("604800");
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const [userProfile, setUserProfile] = useState("interests");
    const [userProfileDataLoaded, setUserProfileDataLoaded] = useState(false);
    const { get } = useDataClient();

    const handleChange = (event: SelectChangeEvent) => {
        setLastDays(event.target.value as string);
    };

    const handleChangePD = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProductionDomain(event.target.checked as boolean);
    };

    const handleChangeUserAttribute = (event: SelectChangeEvent) => {
        setUserProfile(event.target.value as string);
    };

    let availableProfiles0 = useMemo(() => {
        return [] as string[];
    }, []);

    const referenceData = useQuery([appId], () => getPages<PageFile[]>(get)(appId));

    const analyticsData = useQuery([lastDays, appId, isProductionDomain], () => getAnalyticsData(get, appId, lastDays, isProductionDomain));

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    },
    [appId, get]);

    useEffect(() => {
        document.title = 'Beebot AI - Analytics';
    },[]);

    useEffect(() => {
        if (availableProfiles0) {
            setUserProfileDataLoaded(true);
        }
    },[availableProfiles0]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    if (!analyticsData.isLoading && !referenceData.isLoading) {
        analyticsData.data = addReferenceData(analyticsData.data, referenceData.data);
        availableProfiles0 = getUniqueProfileAttributes(analyticsData.data);
        analyticsData.data = transformProfileData(analyticsData.data);
    }

    return (
        <FavoriteProvider>
            <Container sx={{ marginBottom: "5rem" }} className="bb-analytics-page">
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Analytics: {appData.displayName}</h2>
                    <FavoriteButton page={`applications/${appId}/analytics`} displayName={`App: ${appData.displayName}`} displayModule={`Module: Analytics`} />
                    <AppLinks />
                </Box>
                <Breadcrumb />
                <Grid className="bb-mb-4" container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={12} md={12} lg={6}>
                        <Alert className="bb-title-info bb-mb-2" severity="info">
                        Check out your apps latest visitor statistics.
                        </Alert>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box className="bb-analytics-dom">
                            <FormLabel component="legend">Included Domains</FormLabel>
                            <FormGroup>
                                <Tooltip title="Include Non-Production Domain Data?">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isProductionDomain}
                                                size="small"
                                                onChange={handleChangePD}
                                            />
                                        }
                                        label={isProductionDomain ? "Production Only" : "All"}
                                        labelPlacement='end'
                                    />
                                </Tooltip>
                            </FormGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id="analytics-simple-select-label">Date Range</InputLabel>
                            <Select
                                labelId="analytics-simple-select-label"
                                id="analytics-simple-select"
                                value={lastDays}
                                label="Date Range"
                                onChange={handleChange}
                            >
                                <MenuItem value={"86400"}>Last 24 Hours</MenuItem>
                                <MenuItem value={"259200"}>Last 3 Days</MenuItem>
                                <MenuItem value={"604800"}>Last 7 Days</MenuItem>
                                <MenuItem value={"2592000"}>Last 30 Days</MenuItem>
                                <MenuItem value={"currentmonth"}>Current Month</MenuItem>
                                <MenuItem value={"lastmonth"}>Last Month</MenuItem>
                                <MenuItem value={"31536000"}>Last 12 Months</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box className="bb-mb-4 animated animatedFadeInUp fadeInUp4">
                    <AnalyticsAppSummaryGrid loading={analyticsData.isLoading} data={analyticsData.data} />
                </Box>
                {analyticsData.isLoading ? null : (
                    <Box className="bb-ui-box animated animatedFadeInUp fadeInUp5 bb-flex bb-flex-column">
                        <Typography variant="h3" component="h3" className="bb-tac">Sessions</Typography>
                        <Box>
                            <AnalyticsBarChart data={analyticsData.data} timeunit={lastDays === "86400" ? "hour" : "day"} simpleData={false} ylabel={"Sessions"}></AnalyticsBarChart>
                        </Box>
                    </Box>
                )}
                {analyticsData.isLoading ? null : (
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                        className="bb-mb-4 bb-ui-box animated animatedFadeInUp fadeInUp6">
                        <Box sx={{ width: "100%" }}><Typography variant="h3" component="h3" className="bb-tac">Users</Typography></Box>
                        <Grid item xs={4}>
                            <AnalyticsPieChart data={analyticsData.data?.["005-plot-cat-ai-location-app"]}
                                groupingVal={"City"} title={"User By City"}></AnalyticsPieChart>
                        </Grid>
                        <Grid item xs={4}>
                            <AnalyticsPieChart data={analyticsData.data?.["005-plot-cat-ai-device-app"]}
                                groupingVal={"bbOS"} title={"User By Device"}></AnalyticsPieChart>
                        </Grid>
                        <Grid item xs={4}>
                            <AnalyticsPieChart data={analyticsData.data?.["005-plot-cat-ai-device-app"]}
                                groupingVal={"bbBrowser"} title={"User By Browser"}></AnalyticsPieChart>
                        </Grid>
                    </Grid>
                )}
                <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp8">
                    <Grid item xs={12}>
                        {analyticsData.isLoading ? null : (
                            <>
                                <AnalyticsDataGrid
                                    data={analyticsData.data?.["005-summary-ai-app-page"]}
                                    config={AnalyticsConfig_pagedetail}
                                    title="Pages"
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp9">
                    <Grid item xs={12}>
                        {analyticsData.isLoading ? null : (
                            <>
                                <AnalyticsDataGrid
                                    data={analyticsData.data?.["005-bui-ai-app"]}
                                    config={AnalyticsConfig_clickdetail}
                                    title="Content"
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp10">
                    <Grid item xs={12}>
                        {analyticsData.isLoading ? null : (
                            <>
                                <AnalyticsDataGrid
                                    data={analyticsData.data?.["005-summary-ai-app-search"]}
                                    config={AnalyticsConfig_searchdetail}
                                    title="Search"
                                    modalData={analyticsData.data?.["005-session-ai-app-searchfeedback"]}
                                    modalDataSharedColumn="SessionId"
                                    modalConfig={AnalyticsConfig_sessiondetail}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp11">
                    <Grid item xs={12}>
                        {analyticsData.isLoading ? null : (
                            <>
                                <AnalyticsDataGrid
                                    data={analyticsData.data?.["005-summary-ai-app-feedback"]}
                                    config={AnalyticsConfig_feedbackdetail}
                                    title="Feedback"
                                    modalData={analyticsData.data?.["005-session-ai-app-searchfeedback"]}
                                    modalDataSharedColumn="SessionId"
                                    modalConfig={AnalyticsConfig_sessiondetail}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                {analyticsData.isLoading ||  !userProfileDataLoaded  ? <Box className="bb-tac"><CircularProgress /></Box> : (
                    <Grid container className="bb-mb-4 bb-ui-box animated animatedFadeInUp fadeInUp7" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Box sx={{ width: "100%" }}><Typography variant="h3" component="h3" className="bb-tac">User Profiles</Typography>
                            <FormControl style={{ minWidth: 120 }}>
                                <InputLabel id="analytics-simple-select-label">Profile Attribute</InputLabel>
                                <Select
                                    labelId="analytics-userprofile-select-label"
                                    id="analytics-userprofile-select"
                                    label="Date Range"
                                    onChange={handleChangeUserAttribute}
                                    value={userProfile}
                                >
                                    {(availableProfiles0 as string[]).map((name: any) => (
                                        <MenuItem key={name} value={name}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Grid item xs={12}>
                            <AnalyticsGroupBarChart data={analyticsData.data?.["005-summary-ai-app-preferences-chart"]} groupingVal={userProfile} title={""} ylabel={"Users"}></AnalyticsGroupBarChart>
                        </Grid>
                    </Grid>
                )}
                <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp12">
                    <Grid item xs={12}>
                        {analyticsData.isLoading ? null : (
                            <>
                                <AnalyticsDataGrid
                                    data={analyticsData.data?.["005-summary-ai-app-preferences"]}
                                    config={AnalyticsConfig_preferencedetail}
                                    title="User Profiles"
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Box className="bb-mb2"></Box>
            </Container>
        </FavoriteProvider>
    );
};

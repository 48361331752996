/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Box,
    Typography,
    Container,
    CircularProgress,
    Button,
    Grid,
    Chip,
    Alert
} from '@mui/material';
import { AppViewModel } from "../../../services/model/apps/AppViewModel";
import { getApp } from "../../../services/api/FrontendService";
import { getLandingAnalytics } from "../../../services/api/NotificationService";
import useDataClient from "../../../axios/dataClient";
import { GridRenderCellParams } from '@mui/x-data-grid';
import { NotificationsSummaryGrid } from "../../Statistics/NotificationsSummaryGrid";
import { AnalyticsBarChart } from "../../analytics/AnalyticsBarChart";
import { AnalyticsDataGrid } from "../../analytics/AnalyticsDataGrid";
import { AppLinks } from "../AppLinksComponent";
import { Breadcrumb } from "../BreadCrumbComponent";
import { FavoriteProvider } from '../../favorites/FavoriteContext';
import { FavoriteButton } from '../../favorites/FavoriteButton';
import { AnalyticsConfig_notificationdetail } from "../../analytics/AnalyticsDataGridModal";
export const NotificationsLanding = () => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();
    const [landingAnalyticsData, setLandingAnalyticsData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const [fetchedAppData, fetchedLandingAnalyticsData] = await Promise.all([
                    getApp(get)(appId),
                    getLandingAnalytics(get)(appId)
                ]);
                setAppData(fetchedAppData);
                setLandingAnalyticsData(fetchedLandingAnalyticsData);
            }
        };

        fetchData();
    }, [appId, get]);

    useEffect(() => {
        document.title = 'Beebot AI - Notifications Landing Page';
    },[]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    const broadcastGridConfig = {
        title: "",
        initialState: {
            sorting: {
                sortModel: [{ field: "timestamp", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 25 } }
        },
        columnDef: [
            {
                timestamp: {
                    label: "Timestamp"
                }
            },
            {
                user: {
                    label: "Creator"
                }
            },
            {
                title: {
                    label: "Title"
                }
            },
            {
                channels: {
                    label: "Channels"
                }
            },
            {
                filters: {
                    label: "Audience Filters"
                }
            },
            {
                sentSuccessCount: {
                    label: "Sent"
                }
            },
            {
                sentOpenedCount: {
                    label: "Opened"
                }
            }
        ], columns: [
            { field: "ViewSession", headerName: "View", flex: 0.3,renderCell: () => {
                return (
                    <>
                        <IconButton
                            color="primary"
                            aria-label="view details"
                            className="bb-p0 bb-m0"
                            size="small"
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </>
                );
            } },
            {
                field: "timestamp",
                headerName: "Timestamp",
                flex: 1.1,
                valueFormatter: (param: any) => new Date(param).toISOString().replace(/T/, ' ').replace(/\..+/, '')  },
            { field: "user", headerName: "Creator", flex: 1 },
            { field: "title", headerName: "Title", flex: 1 },
            { field: "channels", headerName: "Channels", flex: 1, renderCell: (params: GridRenderCellParams<any, any>) => {
                return ( <div> { params.value ? (params.value.map((gateway: any) => <Chip size={"small"} key={gateway} label={gateway} />)) : null} </div> ); }
            },
            {
                field: "filters",
                headerName: "Audience Filters",
                flex: 2,
                renderCell: (params: GridRenderCellParams<any, any>) => {
                    return ( <div> { params.value ? (
                        params.value.map(
                            (gateway: any) =>
                                gateway.items.map(
                                    (kvp: any ) => <Chip size={"small"} key={gateway.category + '-' + kvp}  label={kvp} />) ) ) : null} </div> ); }
            },
            { field: "sentSuccessCount", headerName: "Sent", flex: 0.4 },
            { field: "sentOpenedCount", headerName: "Opened", flex: 0.8 },
        ]
    };

    const unitPlot = "day";

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Notifications: {appData.displayName}</h2>
                    <FavoriteButton page={`applications/${appId}/notifications/landing`} displayName={`App: ${appData.displayName}`} displayModule={`Module: Notifications`} />
                    <AppLinks />
                </Box>
                <Breadcrumb />
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info"
                    action={
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={`/applications/${appId}/notifications`}
                            sx={{ marginBottom: ".3rem" }}
                            className="animated animatedFadeInUp fadeInUp7"
                        >
                        New Notification
                        </Button>
                    }
                >
                Your notification history and statistics.
                </Alert>
                <Box className="bb-mb-2 animated animatedFadeInUp fadeInUp4">
                    <NotificationsSummaryGrid loading={landingAnalyticsData ? false : true} data={landingAnalyticsData?.cards} />
                </Box>
                {!landingAnalyticsData ? null : (
                    <Box className="bb-ui-box animated animatedFadeInUp fadeInUp5 bb-flex bb-flex-column">
                        <Typography variant="h3" component="h3" className="bb-tac">Notification Timeline</Typography>
                        <Box>
                            <AnalyticsBarChart data={landingAnalyticsData?.timeline} timeunit={ unitPlot } simpleData={true} title={""} ylabel={"Notifications Sent"}></AnalyticsBarChart>
                        </Box>
                    </Box>
                )}
                <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp6 bb-pt-2" sx={{ border: "0px", boxShadow: "none" }}>
                    <Grid item xs={12} className="bb-notifications-table bb-pt-3" sx={{ border: "0px" }}>
                        {!landingAnalyticsData ? null : (
                            <>
                                <AnalyticsDataGrid
                                    data={landingAnalyticsData?.broadcasts.pastNotifications}
                                    config={broadcastGridConfig}
                                    title="Notification History"
                                    transformed={true}
                                    modalData={landingAnalyticsData?.history}
                                    modalConfig={AnalyticsConfig_notificationdetail}
                                    modalDataSharedColumn={"id"}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </FavoriteProvider>
    );
};

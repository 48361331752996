import {
    Chart as ChartJS, ArcElement, Tooltip, Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { bbot_visuals_splitDataByGroupSimple, flattenDictValue, bbot_visuals_cat_reduce, create_colour_dict } from "./AnalyticsHelpers";

ChartJS.register(ArcElement, Tooltip, Legend);

const options: any = {
    plugins: {
        responsive: true,
        autocolors: {
            mode: "data",
            offset: 3
        },
        legend: {
            display: false
        },
        title: {
            display: true,
            text: "App Sessions By Day",
        }
    },
    scales: {
        y: {
            title: { text: 'Users', display: true },
            ticks: { precision: 0 }
        }
    }
};

interface Props {
    data: any | undefined;
    groupingVal: string;
    title: string;
    ylabel: string;
}

export const AnalyticsGroupBarChart = ({ data, groupingVal, title, ylabel }: Props) => {

    const chartConfig = {
        grouping: "type",
        groupingVal,
        autoColour: true,
        dataLabel: "y_measure",
        dataValue: "Users",
        dataOthersLimit: 20,
        chartjsConfig: {
            type: "bar"
        }
    };

    if (!data) {
        return null;
    }

    const datag = data.data;

    const columnNames = [
        {
            y_measure: {
                label: "y_measure"
            }
        },
        {
            y_metric: {
                label: "Users"
            }
        },
        {
            type: {
                label: "type"
            }
        }
    ];

    const flatColumns = flattenDictValue(columnNames);

    const new_col_dict = flatColumns.reduce( (r: any, o: any) => {
        Object.keys(o).forEach( (k: any) => { r[k] = o[k]; });
        return r;
    }, {});

    const cols_dict = [];
    for (const [key,] of Object.entries(datag)) {
        cols_dict.push({ title: key });
    }

    const rows = Object.keys(datag[cols_dict[0].title]);

    const new_data = [];
    for (const r of rows) {
        const tmp_data: { [id: string]: any } = {};
        for (const [key,] of Object.entries(datag)) {
            tmp_data[new_col_dict[key]] = datag[key][r];
        }
        new_data.push(tmp_data);

    }

    const k1 = bbot_visuals_splitDataByGroupSimple(
        new_data,
        "type"
    );

    let k2 = k1[groupingVal];
    if (!k2) {
        return null;
    }

    k2 = bbot_visuals_cat_reduce(k2, chartConfig);

    const value_to_colour = create_colour_dict(k2.map(e => e[chartConfig.dataLabel]));

    const data_obj = {
        labels: k2.map(e => e[chartConfig.dataLabel]),
        datasets: [{
            label: chartConfig.dataValue,
            data: k2.map(e => e[chartConfig.dataValue]),
            backgroundColor: k2.map(e => value_to_colour[e[chartConfig.dataLabel]]),
            borderColor: k2.map(e => value_to_colour[e[chartConfig.dataLabel]]),
            color: k2.map(e => value_to_colour[e[chartConfig.dataLabel]]),
            hoverOffset: 4
        }]
    };
    const copy = structuredClone(options);
    copy.plugins.title.text = title;
    copy.scales.y.title.text = ylabel;
    return <Bar options={copy} data={data_obj} />;
};

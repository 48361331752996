import { ReactNode, useLayoutEffect, useState, useCallback } from "react";
import { Router } from "react-router-dom";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { customHistory } from "./CustomHistory";

import { lastEditorPaths, secondLastEditorPaths, allowedLastEditorPaths } from "./editorRoutes";

export const CustomRouter = ({
    children,
    history,
}: {
    children: ReactNode;
    history: typeof customHistory;
}) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location
    });

    const [showModal, setShowModal] = useState(false);
    const [navigateUrlAfterConfirm, setNavigateUrlAfterConfirm] = useState("");

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalProceed = () => {
        window.location.href = navigateUrlAfterConfirm;

        setShowModal(false);
        setNavigateUrlAfterConfirm("");
    };

    const preventNavigationAndShowModal = useCallback((
        newPathname: string,
        newSecondLastPath: string,
        newLastPath: string): boolean => {

        if (newSecondLastPath &&
            newLastPath &&
            (allowedLastEditorPaths.includes(newLastPath) ||
            secondLastEditorPaths.includes(newSecondLastPath))) {

            // Allowed paths for editor modules without warning
            // Module paths are defined in editorRoutes.ts
            // rooturl/module/moduleId/delete
            // rooturl/module/newmodule

            return false;
        }

        history.back();
        setShowModal(true);
        setNavigateUrlAfterConfirm(newPathname);

        return true;
    }, [history]);

    useLayoutEffect(() => history.listen((listener) => {
        if (showModal) {
            return;
        }

        const previousPaths = state?.location?.pathname?.split("/");
        const newPaths = listener?.location?.pathname?.split("/");

        if (previousPaths && newPaths) {
            // A more elegant solution to show editor warning would involve use of useBlocker and a Data API Router and a newer version of react router
            // But that would involve changing the structure of App.tsx and the way the routes are structured
            // This should be done in future when possible
            // useBlocker - https://reactrouter.com/en/6.26.1/hooks/use-blocker
            // Data API Router - https://reactrouter.com/en/main/routers/picking-a-router
            const previousReversedPaths = previousPaths.reverse();
            const previousSecondLastPath = previousReversedPaths[1];
            const previousLastPath = previousReversedPaths[0];

            const newReversedPaths = newPaths.reverse();
            const newSecondLastPath = newReversedPaths[1];
            const newLastPath = newReversedPaths[0];

            // Check if last path or second to last path for previous location is any editor path
            if (previousSecondLastPath && previousLastPath &&
                (secondLastEditorPaths.includes(previousSecondLastPath)
                    || lastEditorPaths.includes(previousLastPath))) {

                const isNavigtionPrevented = preventNavigationAndShowModal(
                    listener?.location?.pathname,
                    newSecondLastPath,
                    newLastPath);

                if (isNavigtionPrevented) {
                    return;
                }
            }
        }

        setState({
            action: listener.action,
            location: listener.location
        });
    }), [history, state?.location?.pathname, showModal, preventNavigationAndShowModal]);

    return (
        <>
            <Dialog PaperProps={{ style: { minWidth: "500px" } }}
                open={showModal}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center bb-tac">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent className="bb-global-modal">
                    <DialogContentText className="bb-tac">
                        <h2>Any unsaved changes will be lost.</h2>
                        <p>If you have unsaved changes, please click close and hit the <b>save</b> button.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalProceed}>
                        Navigate Away
                    </Button>
                    <Button variant="contained" onClick={handleModalClose} className="bb-ml-auto">
                        Close & Click Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Router
                location={state.location}
                navigationType={state.action}
                navigator={history}
            >
                {children}
            </Router>
        </>
    );
};

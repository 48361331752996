import React from 'react';
import { Card, CardContent, Typography, IconButton, Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFavorites } from './FavoriteContext';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

interface FavoriteCardProps {
    page: string;
    title: string;
    subtitle: string;
}

export const FavoriteCard: React.FC<FavoriteCardProps> = ({ page, title, subtitle }) => {
    const navigate = useNavigate();
    const { removeFavorite } = useFavorites();

    const handleNavigation = () => {
        navigate(page);
    };

    const handleRemoveFavorite = () => {
        removeFavorite(page);
    };

    return (
        <Card sx={{ maxWidth: 400, margin: 2, padding: .6, textAlign: 'center', position: 'relative' }}>
            <Tooltip title="Remove from Dashboard">
                <IconButton
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                    onClick={handleRemoveFavorite}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <CardContent>
                <Tooltip title="Go to this Page">
                    <IconButton sx={{ backgroundColor: '#265b5e', '&:hover': { backgroundColor: '#31747a' } }}>
                        <PushPinOutlinedIcon sx={{ fontSize: 40, color: '#ffffff', padding: '4px' }} onClick={handleNavigation}/>
                    </IconButton>
                </Tooltip>
                <Box sx={{ marginTop: '18px' }}>
                    <Typography gutterBottom variant="h4">
                        {title}
                    </Typography>
                    <Typography variant="h5">
                        {subtitle}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

import { useParams, Link } from "react-router-dom";

import {
    Container,
    Box,
    Alert,
    CircularProgress,
    Button,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination
} from "@mui/material";

import { useState, useEffect, useCallback } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { getAppConfigContent } from "../../../services/api/FrontendAppConfigService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { SchemaType } from "../../../schemas/schemaTypes";

import { Schema } from "../../../schemas/profile/schema";

import { stringSort } from "../../../services/helper/sort";

import { newProfile } from "../../newModuleNames";

import { SearchField } from "../customWidget/Search/SearchField";

import { AppLinks } from "../AppLinksComponent";
import { Breadcrumb } from "../BreadCrumbComponent";

export const AppProfile = () => {
    const { appId } = useParams();
    const { get } = useDataClient();

    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const [profileData, setProfileData] = useState<Schema>();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchString, setSearchString] = useState<string>();
    const [filteredProfileData, setFilteredProfileData] = useState<Schema>();

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    useEffect(() => {
        setSearchString("");
    }, []);

    useEffect(() => {
        if (!profileData?.profile) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        setCurrentPage(0);
        const filtered = profileData.profile.filter((p) =>
            !searchStringUpper || (p?.type && p.type.toUpperCase().includes(searchStringUpper)));
        setFilteredProfileData({ ...profileData, profile: filtered  });
    }, [searchString, profileData]);

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
                const profiles = await getAppConfigContent<Schema>(get)(appId, SchemaType.Profile);
                setProfileData(profiles);
                setFilteredProfileData(profiles);
            }
        };

        fetchData();
    },
    [appId, get]);

    useEffect(() => {
        document.title = 'Beebot AI - Profile';
    },[]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    if (!appData || !filteredProfileData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-0 bb-flex bb-align-items-center">
                <h2 className="bb-m0 bb-p0">Profiles: {appData.displayName}</h2>
                <AppLinks />
            </Box>
            <Breadcrumb />
            <Alert className="bb-title-info bb-mb-2" severity="info">Manage the profiles data for
                your <b>{appData.displayName}</b> application.</Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box className="bb-flex bb-tal bb-mb-2 bb-align-items-center">
                    <h3 className="bb-m0 bb-p0">Profiles</h3>
                    <Box className="bb-med-search">
                        <SearchField
                            searchString={searchString}
                            callSearch={callSearch}
                            timeout={500}
                        />
                    </Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/profiles/${newProfile}`}>
                        <Button variant="contained">
                            New Profile
                        </Button>
                    </Link>
                </Box>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table aria-label="table" className="bb-global-table">
                        <TableHead>
                            <TableRow >
                                <TableCell>Profile Target</TableCell>
                                <TableCell>Options</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{
                            filteredProfileData.profile
                                .sort((a, b) => stringSort(a.type, b.type))
                                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                .map((profile, index) => {
                                    return (
                                        <TableRow key={`profile_${index}`}>
                                            <TableCell sx={{ padding: "0", margin: "0" }}>
                                                <Link className="bb-pages-list bb-align-items-center" to={`/applications/${appId}/profiles/${profile.type}`}>
                                                    <Box className="bb-ml-1">{profile.type}</Box>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {profile.options?.length || 0}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        }
                        </TableBody>
                    </Table>
                    <TablePagination
                        className="bb-global-table-footer"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredProfileData.profile.length}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
        </Container>
    );
};

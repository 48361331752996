import { useParams } from "react-router-dom";
import {
    Container,
    Box,
    Alert,
    CircularProgress
} from "@mui/material";

import { useState, useEffect } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { AppNavigationForm } from "./AppNavigationForm";
import { AppLinks } from "../AppLinksComponent";
import { Breadcrumb } from "../BreadCrumbComponent";
export const AppNavigation = () => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    },
    [appId, get]);

    useEffect(() => {
        document.title = 'Beebot AI - Navigation';
    },[]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-3">
                <h2 className="bb-m0 bb-p0">Navigation: {appData.displayName}</h2>
                <AppLinks />
            </Box>
            <Breadcrumb />
            <Alert className="bb-title-info bb-mb-2" severity="info">Manage the navigation for
                your <b>{appData.displayName}</b> application.</Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <AppNavigationForm appId={appId}></AppNavigationForm>
            </Box>
        </Container>
    );
};

import { useParams, Link } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
    Container,
    Box,
    Alert,
    Grid,
    CircularProgress
} from "@mui/material";

import WebIcon from "@mui/icons-material/Web";

import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";

import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";

import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";

import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";

import { useState, useEffect, useContext } from "react";

import useDataClient from "../../axios/dataClient";

import { getApp } from "../../services/api/FrontendService";

import { AppViewModel } from "../../services/model/apps/AppViewModel";

import { StatCard } from "../Statistics/StatCard";

import { bigNumber } from "../Statistics/StatHelpers";
import { MemberAppContext } from "../../MemberAppContext";

import { AppLinks } from "./AppLinksComponent";
import { Breadcrumb } from "./BreadCrumbComponent";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

export const AppDetails = () => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();

    const appContext = useContext(MemberAppContext);

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    },
    [appId, get]);

    useEffect(() => {
        document.title = 'Beebot AI - Modules';
    },[]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Modules: {appData.displayName}</h2>
                    <FavoriteButton page={`applications/${appId}`} displayName={`App: ${appData.displayName}`} displayModule={`Manage Your App`} />
                    <AppLinks />
                </Box>
                <Breadcrumb />
                <Alert className="bb-title-info bb-mb-2" severity="info">
                Here you will find all the tools you need to manage your <b>{appData.displayName}</b> app.
                </Alert>
                <Box className="bb-mb-3">
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={4} className="animated animatedFadeInUp fadeInUp">
                            <StatCard
                                title="Active"
                                stat={ appData.isActive ? "Yes" : "No" } />
                        </Grid>
                        <Grid item xs={4} className="animated animatedFadeInUp fadeInUp2">
                            <StatCard
                                title="Visits last 30 days"
                                stat={bigNumber( appData.visitorsInTheLast30D )} />
                        </Grid>
                        <Grid item xs={4} className="animated animatedFadeInUp fadeInUp1">
                            <StatCard
                                title="Visits last 24 hours"
                                stat={bigNumber( appData.visitorsInTheLast24H )} />
                        </Grid>
                    </Grid>
                </Box>

                <Grid
                    container
                    spacing={3}
                    alignItems="stretch"
                    alignContent="center">

                    { (appContext.user.isAdmin || appContext.user.isApplicationAdmin) &&
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                        <Card className="bb-app-icon">
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/notifications/landing` }>
                                <Box className="bb-flex bb-justify-content-center bb-card-header bb-home-icons">
                                    <NotificationsActiveOutlinedIcon></NotificationsActiveOutlinedIcon>
                                </Box>
                                <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                    <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Notifications
                                    </Typography>
                                    <Typography variant="body2" fontSize={"0.9rem"
                                    } className="bb-text-center bb-min-height-3">
                                        Send & manage your apps notifiations.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                    }

                    { (appContext.user.isAdmin || appContext.user.isApplicationAdmin) &&
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp4">
                    <Card className="bb-app-icon">
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/pages`}>
                            <Box className="bb-flex bb-justify-content-center bb-card-header bb-home-icons">
                                <WebIcon></WebIcon>
                            </Box>
                            <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                    Pages
                                </Typography>
                                <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                                    Manage your web pages content and content.
                                </Typography>
                            </CardContent>
                        </Link>
                    </Card>
                </Grid>
                    }

                    { (appContext.user.isAdmin || appContext.user.isApplicationAdmin) &&
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp5">
                        <Card className="bb-app-icon">
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId
                            }/theme`}>
                                <Box className="bb-flex bb-justify-content-center bb-card-header bb-home-icons">
                                    <PaletteOutlinedIcon></PaletteOutlinedIcon>
                                </Box>
                                <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                    <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Theme
                                    </Typography>
                                    <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={
                                        "0.9rem"}>
                                       Manage your apps theme and colours.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                    }

                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp6">
                        <Card className="bb-app-icon">
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId
                            }/analytics`}>
                                <Box className="bb-flex bb-justify-content-center bb-card-header bb-home-icons">
                                    <InsertChartOutlinedIcon></InsertChartOutlinedIcon>
                                </Box>
                                <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                    <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                    Analytics
                                    </Typography>
                                    <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={
                                        "0.9rem"}>
                                    Find useful insights and statistics on your apps usage.
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                    {appContext.user.isAdmin &&
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp9">
                        <Card className="bb-app-icon">
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/navigation`}>
                                <Box className="bb-flex bb-justify-content-center bb-card-header bb-home-icons">
                                    <MenuOpenOutlinedIcon></MenuOpenOutlinedIcon>
                                </Box>
                                <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                    <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Navigation
                                    </Typography>
                                    <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                                        Manage your application navigation links
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                    }
                    {appContext.user.isAdmin &&
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp9">
                        <Card className="bb-app-icon">
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/profiles`}>
                                <Box className="bb-flex bb-justify-content-center bb-card-header bb-home-icons">
                                    <SupervisedUserCircleOutlinedIcon></SupervisedUserCircleOutlinedIcon>
                                </Box>
                                <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                    <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Profiles
                                    </Typography>
                                    <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                                        Manage your application profiles
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                    }
                    {appContext.user.isAdmin &&
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp9">
                        <Card className="bb-app-icon">
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/smartPages`}>
                                <Box className="bb-flex bb-justify-content-center bb-card-header bb-home-icons">
                                    <ContactPageOutlinedIcon></ContactPageOutlinedIcon>
                                </Box>
                                <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                    <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Smart Pages
                                    </Typography>
                                    <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                                        Manage your application smart pages
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                    }
                    { appContext.user.isAdmin &&
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp7">
                    <Card className="bb-app-icon">
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/settings`}>
                            <Box className="bb-flex bb-justify-content-center bb-card-header bb-home-icons">
                                <SettingsOutlinedIcon></SettingsOutlinedIcon>
                            </Box>
                            <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                    App Settings
                                </Typography>
                                <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                                    Manage your app settings
                                </Typography>
                            </CardContent>
                        </Link>
                    </Card>
                </Grid>
                    }
                </Grid>
            </Container>
        </FavoriteProvider>
    );
};

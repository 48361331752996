import { Grid } from "@mui/material";

import { StatCard } from "./StatCard";
import { bigNumber } from "./StatHelpers";

interface Props {
    data: any | undefined;
    loading: boolean;
}

export const LiveAgentSummaryGrid = ({ loading, data }: Props) => {

    let values = [0, 0, 0];

    if (data && data.cards) {
        const values2 = data.cards?.data as any;
        values = [values2.activeLiveAgents[0], values2.conversations[0], values2.conversationDuration[0], values2.conversationMsg[0]];
    }

    return (
        <Grid container gap="1rem" className="bb-flex bb-width-100 bb-analytics-big-stat">
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp"><StatCard loading={loading} title="Agents Active"
                stat={
                    bigNumber(values[0])
                } />
            </div>

            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp1"><StatCard loading={loading} title="Conversations"
                stat={
                    bigNumber(values[1])
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp2"><StatCard loading={loading} title="Avg. Mins"
                stat={
                    bigNumber(values[2])
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp3"><StatCard loading={loading} title="Avg. Messages"
                stat={
                    bigNumber(values[3])
                } />
            </div>
        </Grid >
    );
};

/* eslint-disable max-len */

import { Box, Container, Typography, Card, Button } from "@mui/material";
import Grid from "@mui/material/Grid";

import { useContext, useEffect } from "react";

import { MemberAppContext } from "../MemberAppContext";

import banner from '../img/banner.jpg';
import platformassist from '../img/platform-assist.jpg';

import { FavoritesList } from '../components/favorites/FavoritesList';
import { FavoriteProvider } from '../components/favorites/FavoriteContext';

export const Home = () => {
    const { user } = useContext(MemberAppContext);
    const firstName = user.displayName?.split(" ")[0] ?? "Guest";

    useEffect(() => {
        document.title = 'Beebot AI - Dashboard';
    },[]);

    return (
        <FavoriteProvider>
            <Container sx={{ marginBottom: "3rem" }}>
                <Box>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
                        <Grid item xs={12} className="animated animatedFadeInUp fadeInUp1 bb-mt-3">
                            <Card className="bb-p-2 bb-mt-3 bb-flex bb-home-banner">
                                <Box className="bb-flex bb-flex-column bb-justify-content-center bb-p-2 bb-intro-banner-text">
                                    <Typography variant="h2" className="bb-intro-banner-header">Hi <span className="bb-welcome-name">
                                        {firstName}</span>,
                                    </Typography>
                                    <Typography variant="body1" className="bb-mb-2">Welcome to the <strong>Beebot AI Platform</strong>.</Typography>
                                    <Typography variant="body1">Self-Service enables you to <strong>manage your applications and services</strong> across the Beebot AI Platform, while also providing <strong>valuable insights into user engagement.</strong></Typography>
                                    <Button href="/applications" title="My Apps" className="bb-flex bb-mt-2" variant="contained" sx={{ width: "fit-content" }}>My Apps</Button>
                                </Box>
                                <Box className="bb-flex bb-align-items-center bb-width-100 bb-flex-end">
                                    <img src={banner} alt="Image" className="img-responsive bb-banner-img" />
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <Grid>
                    <FavoritesList />
                </Grid>
                {/*<Box className="bb-dash-history animated animatedFadeInUp fadeInUp2 bb-flex-column">
                    <h2 className="bb-mb-4 bb-mt-3">Your History</h2>
                    <Grid container spacing={{ xs: 2 }}  columns={{ xs: 3 }} className="bb-dash-history-links">
                        {!userRecentActivity.data ? (<Box className="bb-tac"><CircularProgress /></Box>) : (
                            userRecentActivity.data && (userRecentActivity.data ?? []).map((recentActivityItem: any) => {
                                return (
                                    <Grid key={recentActivityItem.url} sx={{ padding: ".2rem" }} className="animated animatedFadeInUp fadeInUp1">
                                        <Link href={recentActivityItem.url}>{recentActivityItem.title}</Link>
                                    </Grid>
                                );
                            })
                        )}
                    </Grid>
                </Box>*/}
                <Box>
                    <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
                        <Grid item xs={12} className="animated animatedFadeInUp fadeInUp3">
                            <Card className="bb-p-2 bb-mt-4 bb-mb-2 bb-pt-2 bb-flex bb-release-banner">
                                <Box className="bb-flex bb-flex-column bb-justify-content-center bb-p-2 bb-tac bb-width-100">
                                    <img src={platformassist} alt="Image" className="bb-release-notes-img" />
                                    <Typography variant="h2" className="bb-mt-2">Release Notes</Typography>
                                    <Typography variant="body1">Cooming Soon! Stay up to date with our latest platform enhancements by reading our current product release notes.</Typography>
                                    {/*<Button href={`https://product.bbotapp.com/?page=release-notes`}
                                        target="_blank" title="Read More" className="bb-flex bb-mt-2 bb-mb-2" variant="contained" sx={{ width: "fit-content", margin: "auto" }}>Read More</Button> */}
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </FavoriteProvider>
    );
};

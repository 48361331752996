import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import * as msal from "@azure/msal-browser";

import { QueryClient, QueryClientProvider } from "react-query";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useQueryRequest } from "./axios/useApiQuery";

import { RegisterChatContext } from "./components";
import { MemberAppContext } from "./MemberAppContext";
import {
    authReducer,
    AzureB2CConfiguration,
    ErrorComponent,
    LoadingComponent,
} from "./authentication";
import { allRoutes, AuthorizedRoutes } from "./authorized-routes";
import { CustomerConfigurationContextProvider } from "./CustomerConfigurationContext";
import { AgentState } from "./components/livechat/agentState";
import { ConfigurationContextProvider } from "./ConfigurationContext";

// we need this re-export to make sure things are built correctly - without it, we get weird runtime errors trying to import the routes
export const routes = allRoutes;

export const App: React.FC = () => {
    const queryClient = new QueryClient();

    const [msalInstance, setMsalInstance] =
        useState<msal.PublicClientApplication>();
    const [msalConfig, setMsalConfig] = useState<AzureB2CConfiguration>();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [searchString, setSearchString] = useState("");
    const [state, dispatch] = useReducer(authReducer, {
        isAuthenticated: false,
        isAdmin: false,
        displayName: "",
        isLiveOperator: false,
        isLiveAgentAdmin: false,
        isChatting: AgentState.DISCONNECTED,
        liveAgentTeams: [],
        isApplicationContributor: false,
        isApplicationAdmin: false,
        isCaiContributor: false,
        isCaiAdmin: false,
        isRpaContributor: false,
        isRpaAdmin: false,
        isMediaContributor: false,
        isDataContributor: false,
        isDataAdmin: false,
        sub: "",
    });

    const { data: azureB2CConfiguration } = useQueryRequest<any>(
        "api/auth",
        null
    );

    const toggleSidebar = useCallback((sidebarState: boolean) => {
        setSidebarOpen(sidebarState);
    }, []);

    const updateMemberContextWithTokenData = useCallback(async () => {
        const account = msalInstance?.getAllAccounts()[0];
        if (account === undefined) {
            return;
        }

        dispatch({
            isLiveOperator: (account.idTokenClaims as any).extension_IsLiveOperator ?? false,
            isAdmin: (account.idTokenClaims as any).extension_IsAdmin ?? false,
            liveAgentTeams: (account.idTokenClaims as any).extension_LiveAgentTeams?.split(",") ?? [],
            isLiveAgentAdmin: (account.idTokenClaims as any).extension_IsLiveAgentAdmin ?? false,
            displayName: account.name,
            isApplicationContributor: (account.idTokenClaims as any).extension_IsApplicationContributor ?? false,
            isApplicationAdmin: (account.idTokenClaims as any).extension_IsApplicationAdmin ?? false,
            isCaiContributor: (account.idTokenClaims as any).extension_IsCaiContributor ?? false,
            isCaiAdmin: (account.idTokenClaims as any).extension_IsCaiAdmin ?? false,
            isRpaContributor: (account.idTokenClaims as any).extension_IsRpaContributor ?? false,
            isRpaAdmin: (account.idTokenClaims as any).extension_IsRpaAdmin ?? false,
            isMediaContributor: (account.idTokenClaims as any).extension_IsMediaContributor ?? false,
            isDataContributor: (account.idTokenClaims as any).extension_IsDataContributor ?? false,
            isDataAdmin: (account.idTokenClaims as any).extension_IsDataAdmin ?? false,
            sub: (account.idTokenClaims as any).sub ?? false,
        });
    }, [msalInstance]);

    const updateLiveAgentStatus = useCallback((status: AgentState) => {
        dispatch({
            isChatting: status,
        });
    }, []);

    const updateDisplayName = useCallback((displayName: string) => {
        dispatch({
            displayName
        });
    }, []);

    useEffect(() => {
        if (azureB2CConfiguration) {
            const newMsalConfig: msal.Configuration = {
                auth: {
                    clientId: azureB2CConfiguration.clientId,
                    authority: `${azureB2CConfiguration.instance}tfp/${azureB2CConfiguration.tenantName}/${azureB2CConfiguration.signUpSignInPolicy}`,
                    redirectUri: window.origin,
                    postLogoutRedirectUri: window.origin,
                    knownAuthorities: [
                        `${azureB2CConfiguration.instance}tfp/${azureB2CConfiguration.tenantName}/${azureB2CConfiguration.SignUpSignInPolicy}`,
                    ],
                },
                cache: {
                    cacheLocation: "sessionStorage",
                    storeAuthStateInCookie: true,
                },
            };

            setMsalConfig(azureB2CConfiguration);
            setMsalInstance(new msal.PublicClientApplication(newMsalConfig));
        }
    }, [setMsalInstance, azureB2CConfiguration]);

    return (
        <>
            <ToastContainer />
            {msalInstance ? (
                <MemberAppContext.Provider
                    value={{
                        msalConfig: {
                            msalClientApp: msalInstance,
                            msalConfig,
                        },
                        user: {
                            isLiveOperator: state.isLiveOperator,
                            isAdmin: state.isAdmin,
                            isLiveAgentAdmin: state.isLiveAgentAdmin,
                            displayName: state.displayName,
                            isChatting: state.isChatting,
                            liveAgentTeams: state.liveAgentTeams,
                            isApplicationContributor: state.isApplicationContributor,
                            isApplicationAdmin: state.isApplicationAdmin,
                            isCaiContributor: state.isCaiContributor,
                            isCaiAdmin: state.isCaiAdmin,
                            isRpaContributor: state.isRpaContributor,
                            isRpaAdmin: state.isRpaAdmin,
                            isMediaContributor: state.isMediaContributor,
                            isDataContributor: state.isDataContributor,
                            isDataAdmin: state.isDataAdmin,
                            sub: state.sub,
                        },
                        isAuthenticated: state.isAuthenticated,
                        updateWithTokenData: updateMemberContextWithTokenData,
                        updateLiveAgentStatus,
                        sidebarOpen,
                        setSidebarOpen: toggleSidebar,
                        searchString,
                        setSearchString,
                        updateDisplayName
                    }}>
                    <QueryClientProvider client={queryClient}>
                        <RegisterChatContext>
                            <MsalProvider instance={msalInstance}>
                                <MsalAuthenticationTemplate
                                    interactionType={msal.InteractionType.Redirect}
                                    errorComponent={ErrorComponent}
                                    loadingComponent={LoadingComponent}>
                                    <ConfigurationContextProvider>
                                        <CustomerConfigurationContextProvider>
                                            <AuthorizedRoutes />
                                        </CustomerConfigurationContextProvider>
                                    </ConfigurationContextProvider>
                                </MsalAuthenticationTemplate>
                            </MsalProvider>
                        </RegisterChatContext>
                    </QueryClientProvider>
                </MemberAppContext.Provider>
            ) : (
                <Routes>
                    <Route path="/" element={<p>loading...</p>} />
                </Routes>
            )}
        </>
    );
};

import { Link } from "react-router-dom";

import {
    Container,
    Box,
    Alert,
    CircularProgress,
    Button,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination
} from "@mui/material";

import { useState, useEffect } from "react";

import useDataClient from "../../axios/dataClient";

import { getAppConfigContent } from "../../services/api/FrontendAppConfigService";

import { SchemaType } from "../../schemas/schemaTypes";

import { Schema } from "../../schemas/pathways/schema";

import { stringSort } from "../../services/helper/sort";

import { newPathway } from "../newModuleNames";

export const Pathways = () => {
    const { get } = useDataClient();

    const [pathwayData, setPathwayData] = useState<Schema>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    useEffect(() => {
        document.title = 'Beebot AI - Pathways';
    },[]);

    useEffect(() => {
        const fetchData = async () => {
            const pathways = await getAppConfigContent<Schema>(get)("noApp", SchemaType.Pathways);
            setPathwayData(pathways);
        };

        fetchData();
    },
    [get]);

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };

    if (!pathwayData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box className="bb-title-bar bb-mb-2">
                <h2 className="bb-m0 bb-p0">Pathways</h2>
            </Box>
            <Alert className="bb-title-info bb-mb-1" severity="info">Manage your pathways on the Beebot AI Platform.&nbsp;
                <a rel="noreferrer" className="bb-mr-1" href={`https://product.bbotapp.com/?page=acp`}
                    target="_blank">Learn More</a>
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column">
                <Box className="bb-flex bb-tal bb-align-items-center bb-mb-2">
                    <h3 className="bb-m0 bb-p0">Your Pathways</h3>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/pathways/${newPathway}`}>
                        <Button variant="contained">
                            New Pathway
                        </Button>
                    </Link>
                </Box>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }} className="bb-m0 bb-p0 bb-global-table">
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow >
                                <TableCell>Pathway Name</TableCell>
                                <TableCell>No. of Stages</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{
                            pathwayData.pathways
                                .sort((a, b) => stringSort(a.name, b.name))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((pathway, index) => {
                                    return (
                                        <TableRow key={`pathway_${index}`}>
                                            <TableCell>
                                                <Link className="bb-pages-list bb-align-items-center" to={`/pathways/${pathway.id}`}>
                                                    {pathway.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {pathway.stages?.length || 0}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        }
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[15, 20, 30, 40]}
                        component="div"
                        count={pathwayData.pathways.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={"bb-global-table-footer"}
                    />
                </TableContainer>
            </Box>
        </Container>
    );
};

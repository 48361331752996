import { Box, Button, CircularProgress, Divider, Grid, IconButton, Skeleton, Typography } from "@mui/material";

import { useLocation } from "react-router-dom";

import { useCallback, useContext, useState, useEffect } from "react";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

import { Cancel, DeleteForever, SelectAll, Upload } from "@mui/icons-material";

import useDataClient from "../../axios/dataClient";
import { useAsync } from "../../useAsync";

import { MemberAppContext } from "../../MemberAppContext";

import { error } from "../ErrorDisplay";

import { FolderCard } from "./folderCard";
import { File, FileProps } from "./file";
import { CreateFolderDialog } from "./createFolder";
import { UploadAssetDialog } from "./uploadAsset";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

export interface FolderInfo {
    path: string;
    name: string;
    isPublic: boolean;
    subfolders: string[];
    files: FileProps[];
}

const splitPath = (fullPath?: string): { path: string; name: string; isPublic: boolean } => {
    if (fullPath === undefined) {
        return { path: "", name: "", isPublic: false };
    }

    const segments = fullPath.split("/");
    if (segments.length === 0) {
        return { path: "", name: "", isPublic: false };
    }

    // There is an empty segment, as location.pathname always starts with a leading slash
    return {
        path: segments.map(segment => decodeURI(segment)).slice(3).join("/"),
        name: decodeURI(segments[segments.length - 1]),
        isPublic: segments[2] === "public"
    };
};

export interface BlobFile {
    name: string;
    path: string;
    url: string;
    lastUpdated?: Date | string;
}

export const Folder = () => {
    const location = useLocation();
    const { path, name, isPublic } = splitPath(location.pathname);
    const [contents, setContents] = useState<{ subfolders: string[]; files: BlobFile[] }>({ subfolders: [], files: [] });
    const { post, deleteRequest } = useDataClient();
    const [isLoading, setIsLoading] = useState(false);
    const [createFolderOpen, setCreateFolderOpen] = useState(false);
    const [uploadAssetOpen, setUploadAssetOpen] = useState(false);
    const [searchResult, setSearchResults] = useState<BlobFile[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isMultipleDeleting, setIsMultipleDeleting] = useState(false);
    const [runningIsMultipleDeleting, setRunningIsMultipleDeleting] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState<BlobFile[]>([]);
    const [currentlyDisplayedFileCount, setCurrentlyDisplayedFileCount] = useState(24);
    const { searchString } = useContext(MemberAppContext);

    useEffect(() => {
        document.title = 'Beebot AI - Media Gallery';
    },[]);

    const fetchFolders = useCallback(async () => {
        setIsLoading(true);
        setContents({ subfolders: [], files: [] });
        const formData = new FormData();
        formData.append("folderPath", path);
        formData.append("isPublic", isPublic.toString());
        const value = await post<{ subfolders: any[]; files: any[] }>("api/asset", formData);
        setIsLoading(false);
        return value;
    }, [post, path, isPublic]);

    useAsync(fetchFolders, setContents, []);

    const openCreateFolder = useCallback(() => {
        setCreateFolderOpen(true);
    }, []);

    const closeCreateFolder = useCallback(() => {
        setCreateFolderOpen(false);
    }, []);

    const createFolder = useCallback((newFolderName: string) => {
        setCreateFolderOpen(false);
        const subfolders = [...contents.subfolders, newFolderName];
        setContents({ subfolders, files: contents.files });
    }, [contents]);

    const openUploadAsset = useCallback(() => {
        setUploadAssetOpen(true);
    }, []);

    const closeUploadAsset = useCallback(() => {
        setUploadAssetOpen(false);
    }, []);

    const uploadAsset = useCallback((newFiles: BlobFile[]) => {
        const allFiles = [...newFiles, ...contents.files];
        setContents({ ...contents, files: allFiles });
        setUploadAssetOpen(false);
    }, [contents]);

    const fileEdited = useCallback((newFile: BlobFile, oldFile: BlobFile) => {
        const fileIndex = contents.files.findIndex(file => file.name === oldFile.name);
        if (fileIndex !== -1) {
            const newFiles = [...contents.files];
            newFiles.splice(fileIndex, 1, newFile);
            setContents({ ...contents, files: newFiles });
        }
    }, [contents]);

    const fileDeleted = useCallback((fileName: string) => {
        const fileIndex = contents.files.findIndex(file => file.name === fileName);
        if (fileIndex !== -1) {
            const newFiles = [...contents.files];
            newFiles.splice(fileIndex, 1);
            setContents({ ...contents, files: newFiles });
        }
    }, [contents]);

    const startMultipleDelete = useCallback(() => {
        setIsMultipleDeleting(true);
    }, []);

    const cancelMultipleDelete = useCallback(() => {
        setFilesToDelete([]);
        setIsMultipleDeleting(false);
    }, []);

    const selectAllOnPage = useCallback(() => {
        setFilesToDelete(contents.files.slice(0, currentlyDisplayedFileCount));
    }, [contents, currentlyDisplayedFileCount]);

    const toggleForDeletion = useCallback((file: BlobFile, isCurrentlySelected: boolean) => {
        if (isCurrentlySelected) {
            setFilesToDelete(current => current.filter(currentFile => currentFile !== file));
        }
        else {
            setFilesToDelete(current => [...current, file]);
        }
    }, []);

    const completeMultipleDelete = useCallback(async () => {

        setRunningIsMultipleDeleting(true);

        const pageNamesWithLinks = await post<string[]>("api/asset/validateMediaUse", {
            urls: filesToDelete.map(file => file.url)
        });

        if (pageNamesWithLinks.length > 0) {
            for (const pageNameWithLinks of pageNamesWithLinks) {
                error(`Cannot delete files as some of them are included in page '${pageNameWithLinks}'`);
            }

            setRunningIsMultipleDeleting(false);
            return;
        }

        const deletedFiles = await deleteRequest<{ successfulFiles: string[]; errors: string[] }>("api/asset/multipleDelete", {
            fileNames: filesToDelete.map(file => file.name),
            isPublic,
            filePath: path
        });
        for (const errorString of deletedFiles.errors) {
            error(`failed to delete file ${errorString}`);
        }
        setContents({ ...contents, files: contents.files.filter(file => !deletedFiles.successfulFiles.includes(file.name)) });
        setFilesToDelete([]);
        setRunningIsMultipleDeleting(false);
        setIsMultipleDeleting(false);
    }, [deleteRequest, filesToDelete, contents, isPublic, path, post]);

    const runSearch = useCallback(async () => {
        if (searchString === "") {
            return [];
        }
        setIsSearching(true);
        try {
            const object = {
                searchFilter: searchString,
                isPublic,
                relativePath: path
            };
            const results = await post<BlobFile[]>("api/asset/search", object);
            setIsSearching(false);
            return results;
        }
        catch {
            setIsSearching(false);
            error("something went wrong");
            return [];
        }
    }, [searchString, isPublic, path, post]);

    const loadMore = useCallback(() => {
        setCurrentlyDisplayedFileCount(current => current + 24);
    }, []);

    useAsync(runSearch, setSearchResults, [searchString, post, isPublic, path]);

    if (searchString !== "") {
        return (
            <Box id="search-results">
                <Typography variant="h2">{name}</Typography>
                {isSearching ?
                    <Typography>Searching</Typography> :
                    <Grid container spacing={2}>
                        {searchResult.slice(0, currentlyDisplayedFileCount).map(file =>
                            (<File
                                key={file.path + file.name}
                                file={file}
                                isPublic={isPublic}
                                isMultipleDeleting={isMultipleDeleting}
                                isBeingDeleted={filesToDelete.includes(file)}
                                toggleForDeletion={toggleForDeletion}
                                onSuccessfulEdit={fileEdited}
                                onSuccessfulDelete={fileDeleted} />))}
                    </Grid>}
            </Box>
        );
    }

    return (
        <FavoriteProvider>
            <Box>
                <Box sx={{ display: "flex" }}>
                    <Typography className="bb-med-lib-header" variant="h2">Media Library: {name}</Typography>
                    <FavoriteButton page={`assets/public`} displayName={`Media Library`} displayModule={`View your Assets`} />
                    <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }} >
                        <Box>
                            {!isMultipleDeleting ? <IconButton onClick={startMultipleDelete} >
                                <Box><Button variant="outlined" size="small"><CheckBoxOutlineBlankIcon />
                                    <Typography className="bb-media-label"> Select</Typography></Button></Box>
                            </IconButton> :
                                <Box>
                                    <IconButton onClick={cancelMultipleDelete}><Button variant="outlined" size="small"><Cancel />
                                        <Typography className="bb-media-label"> Cancel</Typography></Button>
                                    </IconButton>
                                    <IconButton onClick={selectAllOnPage}>
                                        <Button variant="outlined" size="small"><SelectAll />
                                            <Typography className="bb-media-label"> Select All</Typography>
                                        </Button>
                                    </IconButton>
                                    <IconButton onClick={completeMultipleDelete}>
                                        <Button variant="outlined" color="error" size="small"><DeleteForever />
                                            <Typography className="bb-media-label"> Delete</Typography></Button>
                                    </IconButton>
                                </Box>}
                        </Box>
                        <Box>
                            <Button onClick={openUploadAsset} variant="contained"><Upload /> Upload</Button>
                        </Box>
                    </Box>
                </Box>
                {isLoading ? <div className="bb-tac"><CircularProgress /></div> :
                    <Box id="list-results">
                        <Divider sx={{ marginTop: "0.5em", marginBottom: "1.5em" }} />
                        <Grid
                            container
                            spacing={1} className="bb-folder-list bb-align-items-center bb-mb-2">
                            <Box className="bb-folder-up"><FolderCard name=".." parentPath={path} isPublic={isPublic} /></Box>
                            {contents.subfolders.map(subfolder =>
                                (<Grid key={subfolder} item xs={12} sm={12} md={6} lg={2} xl={2}>
                                    <FolderCard name={subfolder} parentPath={path} isPublic={isPublic} />
                                </Grid>)
                            )}
                            <CreateFolderDialog open={createFolderOpen} onCancel={closeCreateFolder} onConfirm={createFolder} />
                            <Button className="bb-add-folder" onClick={openCreateFolder}>
                                <CreateNewFolderIcon />
                            </Button>
                        </Grid>
                        <Grid container spacing={2}>
                            {contents.files.slice(0, currentlyDisplayedFileCount).map(file =>
                                runningIsMultipleDeleting && filesToDelete.includes(file) ?
                                    <Grid xs={12} sm={6} md={4} lg={3} xl={2} key={file.name} item>
                                        <Skeleton variant="rectangular" width={210} height={118} />
                                    </Grid> :
                                    (<File key={file.name} file={file} isPublic={isPublic} isMultipleDeleting={isMultipleDeleting}
                                        isBeingDeleted={filesToDelete.includes(file)} toggleForDeletion={toggleForDeletion} onSuccessfulEdit={fileEdited}
                                        onSuccessfulDelete={fileDeleted} />))}
                        </Grid>
                    </Box>
                }
                <Box sx={{ width: "100%", margin: "2rem", textAlign: "center" }}>
                    <Button className="bb-primary-button" onClick={loadMore}>Load More</Button></Box>
                <UploadAssetDialog open={uploadAssetOpen} filePath={path} isPublic={isPublic} isSingleFile={false} onCancel={closeUploadAsset}
                    onConfirm={uploadAsset} existingAssets={contents.files} />
            </Box>
        </FavoriteProvider>
    );
};

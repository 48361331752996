import * as React from "react";

import { CardContent, Grid, Card, CardHeader } from "@mui/material";

import { getPanelPreview } from "../preview/panels/GetPanelPreview";

import { PanelType, panelTypeDisplayNames } from "../../../schemas/panelTypes";

export interface PageEditPanelProps {
    panelIndex: number;
    editButton: JSX.Element;
    deleteButton: JSX.Element;
    panelType: string;
    panel: any;
}

export const PageEditPanel: React.FC<PageEditPanelProps> = ({ editButton, deleteButton, panelType, panel }) => {
    const panelName = panelTypeDisplayNames[panelType as PanelType] || panelType;
    return (
        <Grid item xs>
            <Card variant="outlined">
                {/*Panel Header*/}
                <CardHeader className="bb-pe-panel-title bb-pe-titles"
                    titleTypographyProps={{
                        textAlign: "left"
                    }}
                    action={[
                        editButton,
                        deleteButton]
                    }
                    title={
                        `${panelName}`
                    }
                />
                <CardContent>
                    {/*Returns a component based on what panel type you want to preview*/}
                    { getPanelPreview(panel, panelType) }
                </CardContent>
            </Card>
        </Grid>
    );
};

import { useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Box, Button, IconButton, Typography } from "@mui/material";
import HighlightOff from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";

import { theme } from "../../theme";

import { ChatMessage } from "./ChatWindow";
import { TransferDialog } from "./TransferDialog";

export interface ChatInformationProps {
    chatId: string;
    botName: string;
    userName: string;
    userEmail: string;
    initialMessage: ChatMessage;
    handleCloseChatInformation: () => void;
    messageHistory: ChatMessage[];
}

export const ChatInformation = (props: ChatInformationProps) => {
    const { initialMessage, userEmail, userName, handleCloseChatInformation } = props;
    const hours = initialMessage.time.getHours().toLocaleString();
    const minutes = initialMessage.time.getMinutes().toLocaleString();
    const formatSingleDigitTime = (timeString: string) => {
        return timeString.length === 1 ? `0${timeString}` : timeString;
    };
    const chatStartTime = `${formatSingleDigitTime(hours)}:${formatSingleDigitTime(minutes)}`;

    const [isTransferDialogVisible, setIsTransferDialogVisible] = useState(false);
    const showTransferDialog = useCallback(() => setIsTransferDialogVisible(true), []);
    const handleCloseTransfer = useCallback(() => setIsTransferDialogVisible(false), []);

    const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

    return (
        <Box className="chat-info" sx={{
            display: "flex",
            flex: "0 1",
            flexDirection: "column",
            padding: theme.spacing(2),
            borderLeft: `1px solid ${theme.palette.divider}`,
        }}>
            <Box
                display="flex"
                alignItems="center"
                paddingBottom={isMobile ? 0 : theme.spacing(4)}
                justifyContent={isMobile ? "flex-end" : ""}
            >
                {isMobile ? (
                    <><IconButton onClick={handleCloseChatInformation} className="bb-ml-auto">
                        <CancelIcon color="primary" fontSize="small"/>
                    </IconButton></>

                ) : (
                    <>
                        <Typography variant="h4" className="bb-mb-0">Chat Information</Typography>
                        <IconButton onClick={handleCloseChatInformation} className="bb-ml-auto">
                            <HighlightOff color="primary" fontSize="small" />
                        </IconButton>
                    </>
                )}
            </Box>
            <Typography data-testid="chat-information-username"
                sx={{
                    wordBreak: "break-word",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                }}>
                <b>Name</b><br /> <i>{userName}</i><br /><br />
            </Typography>
            <Typography data-testid="chat-information-email"
                sx={{
                    wordBreak: "break-word",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                }}>
                <b>Email</b><br /> <i>{userEmail}</i><br /><br />
            </Typography>
            <Typography data-testid="chat-information-question"
                sx={{
                    wordBreak: "break-word",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 7,
                }}>
                <b>Question</b><br /> <i>{initialMessage.message}</i><br /><br />
            </Typography>
            <Typography
                data-testid="chat-information-chat-start">
                <b>Chat start</b><br /> <i>{chatStartTime}</i><br /><br />
            </Typography>
            <br />
            <Button variant="outlined" size="small"
                onClick={showTransferDialog}>
                Transfer
            </Button>
            <TransferDialog
                open={isTransferDialogVisible}
                chatId={props.chatId}
                botName={props.botName}
                userName={props.userName}
                userEmail={props.userEmail}
                issue={props.initialMessage.message}
                messageHistory={props.messageHistory}
                onClose={handleCloseTransfer}
            />
        </Box>
    );
};

import { Button, Box, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { useCallback } from "react";

import { LiveAgentData } from "../../../services/model/livechat/dashboard/liveAgentData";
import { LoadingCard } from "../../shared/LoadingCard";
import useDataClient from "../../../axios/dataClient";

interface AvailableAgentsProps {
    liveAgentData: LiveAgentData[];
    isLoading: boolean;
    onAgentDisconnected: (agentId: string) => void;
}

export const AvailableAgents = ({ liveAgentData, isLoading, onAgentDisconnected }: AvailableAgentsProps) => {

    const { get } = useDataClient();
    const disconnectAgent = useCallback((agentId: string) => {
        return (async () => {
            await get("api/chat/disconnect/" + agentId);
            onAgentDisconnected(agentId);
        });
    }, [get, onAgentDisconnected]);

    const renderDisconnect = useCallback((params: GridRenderCellParams<any, any>) => {
        if (params.row.statusString !== "DISCONNECTED") {
            return <Typography>N/A</Typography>;
        }
        return (<Button onClick={disconnectAgent(params.row.id)}>DISCONNECT</Button>);
    }, [disconnectAgent]);

    const columns: GridColDef[] = [
        { field: "name", headerName: "Name", flex: 1, minWidth: 100 },
        { field: "teams", headerName: "Teams", flex: 1, minWidth: 100,
            valueFormatter: (params: string[]) => params.join(", ") },
        { field: "statusString", headerName: "Status", flex: 1, minWidth: 100 },
        { field: "activeChats", headerName: "Active Chats", flex: 1, minWidth: 100 },
        { field: "disconnect", headerName: "Disconnect Agent", flex: 1, minWidth: 100, renderCell: renderDisconnect, }
    ];

    if (isLoading) {
        return (<LoadingCard title="Available Live Agents" />);
    }

    return (
        <Box className="bb-mt-2 bb-mb-2 bb-p-2" sx={{ background: "#fff", border: "1px solid #ddd", borderRadius: "7px" }}>
            <Typography variant="h3">Available Live Agents</Typography>
            <DataGrid
                autoHeight
                rows={liveAgentData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5
                        }
                    }
                }}
                disableRowSelectionOnClick
                pageSizeOptions={[5, 10, 25, 50]}
            />
        </Box>
    );
};

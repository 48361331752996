import { Grid } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Alert, Box } from "@mui/material";

import useDataClient from "../../axios/dataClient";
import { MemberAppContext } from "../../MemberAppContext";
import { getCustomerUsers } from "../../services/api/CustomerUserService";
import { CustomerUser, Team } from "../../services/model/users/customerUser";
import { error } from "../ErrorDisplay";

import { UserBox } from "./UserBox";

export const Users = () => {
    const { get } = useDataClient();
    const [users, setUsers] = useState<CustomerUser[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [skipToken, setSkipToken] = useState<string | undefined>();
    const { user: currentUser, searchString } = useContext(MemberAppContext);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Beebot AI - User Management';
    },[]);

    const getUsers = useCallback(async () => {
        const page = await getCustomerUsers(get);
        setSkipToken(page.skipToken);
        setUsers(current => [...current, ...page.items]);
    }, [get]);

    const getTeams = useCallback(async () => {
        const result = await get<Team[]>("api/team");
        setTeams(result);
    }, [get]);

    // leaving this in so we can add loading via pagination if required
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const loadMore = useCallback(async () => {
        if (skipToken) {
            const page = await getCustomerUsers(get, undefined, skipToken);
            setSkipToken(page.skipToken);
            setUsers(current => [...current, ...page.items]);
        }
    }, [get, skipToken]);

    const runSearch = useCallback(async () => {
        const page = await getCustomerUsers(get, searchString, skipToken);
        setSkipToken(page.skipToken);
        setUsers(page.items);
    }, [get, searchString, skipToken]);

    useEffect(() => {
        runSearch();
    }, [runSearch]);

    const userUpdated = useCallback((user: CustomerUser) => {
        const currentUserIndex = users.findIndex(u => u.id === user.id);
        if (currentUserIndex === -1) {
            error("Updated user that doesn't exist");
        }

        const newUsers = [...users];
        newUsers.splice(currentUserIndex, 1, user);
        setUsers(newUsers);
    }, [users]);
    const editSuccess = useCallback((savedUser: CustomerUser) => {
        userUpdated(savedUser);
    }, [userUpdated]);

    useEffect(() => {
        getUsers();
        getTeams();
    }, [getUsers, getTeams]);

    useEffect(() => {
        document.title = 'Beebot AI - Users';
    },[]);

    useEffect(() => {
        // We need the display name check in here because if you reload when on this page,
        // this fires before the context is fully loaded and we see the default value of false. The check makes sure the context has actually loaded
        if (currentUser.displayName !== "" && !(currentUser.isAdmin || currentUser.isLiveAgentAdmin)) {
            navigate("../", { replace: true });
        }
    }, [navigate, currentUser]);

    if (!(currentUser.isAdmin || currentUser.isLiveAgentAdmin)) {
        return <></>;
    }

    return (<>
        <Container>
            <Box>
                <Box className="bb-title-bar"><h2>User Management</h2></Box>
                <Alert className="bb-title-info" severity="info">Here you can manage users and their roles.</Alert>
                <Grid container
                    spacing={2}
                    alignItems="stretch"
                    alignContent="center">
                    {users.map(user => {
                        return (<Grid
                            key={user.id}
                            item xs={12}
                            sm={6}
                            md={6}
                            lg={4}
                            xl={4}>
                            <UserBox user={user} teams={teams} editSuccess={editSuccess} />
                        </Grid>);
                    })}
                </Grid>
                {/* <Button onClick={loadMore}>Load More</Button> */}
            </Box>
        </Container>
    </>);
};

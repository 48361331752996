import React, { useState } from "react";

import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";

import {
    DeploymentItemModel,
    DeploymentProgressText
} from "../../../schemas/deployment";

import { DateDisplay } from "../../shared/DateDisplay";

export interface DeploymentItemTableProps {
    deploymentItems: DeploymentItemModel[];
}

export const DeploymentItemTable: React.FC<DeploymentItemTableProps> = ({ deploymentItems }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };

    return (
        <>
            <Table size="small" aria-label="purchases" className="bb-deployment-sub-items">
                <TableHead>
                    <TableRow>
                        <TableCell>Update</TableCell>
                        <TableCell>UserId</TableCell>
                        <TableCell align="right">Deployment Progress</TableCell>
                        <TableCell align="right">Date Created</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deploymentItems
                        .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item: DeploymentItemModel) => (
                            <TableRow key={item.rowKey}>
                                <TableCell component="th" scope="row">
                                    {item.update}
                                </TableCell>
                                <TableCell>{item.userId}</TableCell>
                                <TableCell align="right">{DeploymentProgressText[item.deploymentProgress]}</TableCell>
                                <TableCell align="right"><DateDisplay value={new Date(item.dateCreated)}
                                    dateStyle="short"
                                    timeStyle="short"/>&nbsp;&nbsp;
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={deploymentItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

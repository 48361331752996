import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { MemberAppContext } from "../../MemberAppContext";

interface Favorite {
    url: string;
    title: string;
    subtitle: string;
    userSub: string; // Use sub instead of displayName
}

interface FavoriteContextType {
    favorites: Favorite[];
    addFavorite: (favorite: Omit<Favorite, 'userSub'>) => void;
    removeFavorite: (url: string) => void;
}

const FavoriteContext = createContext<FavoriteContextType | undefined>(undefined);

export const useFavorites = () => {
    const context = useContext(FavoriteContext);
    if (!context) {
        throw new Error('useFavorites must be used within a FavoriteProvider');
    }
    return context;
};

export const FavoriteProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useContext(MemberAppContext);
    const userSub = user?.sub || 'defaultSub';

    const [favorites, setFavorites] = useState<Favorite[]>([]);

    useEffect(() => {
        const storedFavorites = localStorage.getItem('favorites');
        if (storedFavorites) {
            const parsedFavorites = JSON.parse(storedFavorites);
            const userFavorites = parsedFavorites.filter((fav: Favorite) => fav.userSub === userSub);
            setFavorites(userFavorites);
        }
    }, [userSub]);

    const addFavorite = (favorite: Omit<Favorite, 'userSub'>) => {
        const storedFavorites = localStorage.getItem('favorites');
        const allFavorites = storedFavorites ? JSON.parse(storedFavorites) : [];
        const updatedFavorites = [...allFavorites, { ...favorite, userSub }];
        setFavorites(updatedFavorites.filter((fav: Favorite) => fav.userSub === userSub));
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    };

    const removeFavorite = (url: string) => {
        const storedFavorites = localStorage.getItem('favorites');
        const allFavorites = storedFavorites ? JSON.parse(storedFavorites) : [];
        const updatedFavorites = allFavorites.filter((fav: Favorite) => !(fav.url === url && fav.userSub === userSub));
        setFavorites(updatedFavorites.filter((fav: Favorite) => fav.userSub === userSub));
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    };

    return (
        <FavoriteContext.Provider value={{ favorites, addFavorite, removeFavorite }}>
            {children}
        </FavoriteContext.Provider>
    );
};

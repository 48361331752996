import * as React from 'react';
import { Modal, Box, Typography, Button, DialogTitle } from '@mui/material';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface WarningModalProps {
    open: boolean;
    description: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const WarningModal: React.FC<WarningModalProps> = ({ open, description, onConfirm, onCancel }) => {
    return (
        <Modal open={open} onClose={onCancel} aria-labelledby="warning-modal-title" aria-describedby="warning-modal-description" className="bb-delete-modal">
            <Box className="bb-delete-modal bb-global-modal" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center bb-tac">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <Typography id="warning-modal-description" sx={{ mt: 4 }}>
                    {description}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', background: "#eee" }} className="bb-mt-4 bb-p-2">
                    <Button variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={onConfirm}>
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

import React, { useCallback } from 'react';
import { TextEditor } from './TextEditor'; // Import the updated TextEditor component

const HtmlCustomWidget = (props: any) => {
    const handleChange = useCallback((value: string) => {
        props.onChange(value);
    }, [props]);

    return (
        <div className="html-custom-widget">
            <label htmlFor={props.id} className="widget-label">
                {props.label || props.schema.title || 'Rich Text Content'}
                {props.required ? '*' : null}
            </label>
            <TextEditor value={props.value} onChange={handleChange} />
        </div>
    );
};

export { HtmlCustomWidget };

export {};

/* eslint-disable max-len */

import * as React from "react";

import { Grid, Box, Link } from "@mui/material";

export interface BBButtonStackProps {
    panel: any;
}

export const BBButtonStack: React.FC<BBButtonStackProps> = ({ panel }) => {
    let buttonBG = "";
    if (panel?.links?.ButtonBgColor === "") {
        buttonBG = "#dddddd";
    }
    else {
        buttonBG = panel?.links?.ButtonBgColor;
    }
    let buttonText = "";
    if (panel?.links?.ButtonTextColor === "") {
        buttonText = "#ffffff";
    }
    else {
        buttonText = panel?.links?.ButtonTextColor;
    }
    return (
        <Box>
            <Grid>
                {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img-button-stack" />) : (<span></span>)}
            </Grid>
            <Grid>
                {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
                {panel?.panelPara ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
            </Grid>
            <Grid item xs className="bb-tal bb-button-stack bb-flex bb-flex-wrap">
                {panel?.links?.map((link: any, index: any) => (
                    <Link
                        key={index}
                        href={link?.linkUrl}
                        className="bb-mb-1 bb-mt-1 bb-button-stack-button"
                        sx={{
                            textDecoration: "none",
                            fontWeight: "normal",
                            padding: "1rem",
                            borderRadius: "5px",
                            background: buttonBG,
                            color: buttonText,
                            width: "fit-content"
                        }}>
                        {link?.linkText}
                    </Link>
                ))}
            </Grid>
        </Box>
    );
};
